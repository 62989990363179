import { Dispatch, SetStateAction, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RowExam } from "../Type/RowExam";
import { Badge, ListGroup } from 'react-bootstrap';

function ModalExamInfo({exam,onClose}:{exam:RowExam|null,onClose:Dispatch<SetStateAction<RowExam|null>>}) {
    if (exam===null)
        return <></>

  return (
    <>
      <Modal
        show={exam!==null}
        onHide={() => onClose(null)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Informacje o egzaminie {exam.SessionSeasonName} {exam.SessionYear}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ListGroup>
                {exam.Categories.map(function(i,idx){
                    return <ListGroup.Item key={`catinfo-${idx}}`} className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">{i.Name}</div>
                        <Badge bg="primary" pill>{i.Count} {i.Count===1?'pytanie':'pytań'}</Badge>
                        </ListGroup.Item>
                })}
            </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onClose(null)}>Zamknij</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalExamInfo;