import { Alert, Badge, Button, ButtonGroup, CardTitle, Dropdown, ListGroup } from "react-bootstrap";
import { QuestionFavoriteButton } from "./Favorite";
import { QuestionEdit, QuestionProposal, QuestionTitle } from "./Title";
import { RecordQuestionDescription } from "../../Type/RecordQuestionDescription";
import { RecordQuestionPreview } from "../Interface/QuestionPreview";
import { MdLink } from "react-icons/md";

export function QuestionCardContent({rowAbout,rowQuestion,showAbout,disableProposalBtn}:
                                    {rowAbout:RecordQuestionDescription|null,rowQuestion:RecordQuestionPreview,showAbout:boolean,disableProposalBtn?:boolean}){
    let questionID              = rowQuestion.ID;

    if (rowQuestion===null) return <></>


    let questionPreview = `https://zdajlekko.pl/app/pytanie/${questionID}`;

    // console.log(rowAbout);

    // console.log(navigator);
    return <>
            <CardTitle className="d-flex flex-lg-row position-relative flex-column-reverse">
                <Dropdown as={ButtonGroup} className="align-self-lg-center me-lg-2 me-auto">
                    <QuestionFavoriteButton QuestionID={questionID!} FavoriteID={rowQuestion.FavoriteID!} />
                    {/* {disableProposalBtn===true?null:<QuestionProposal question={rowQuestion} />} */}
                    <QuestionEdit question={rowQuestion} />
                    {/* <Dropdown.Toggle size="sm" split id="dropdown-split-basic" />
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {navigator.clipboard.writeText(questionPreview)}}><MdLink /> Skopiuj adres pytania do schowka</Dropdown.Item>
                    </Dropdown.Menu> */}
                </Dropdown>
                {/* <ButtonGroup aria-label="Akcje na pytaniu" className="me-1"> */}
                    
                    
                {/* </ButtonGroup> */}
                {/* <QuestionShareButton QuestionID={questionID!} /> */}
                 <QuestionTitle question={rowQuestion} />
                {/* <QuestionReportButton data={data} /> */}
            </CardTitle>
                {/* <CardText> */}
                    <div className="fs-4 mb-2" style={{whiteSpace: "pre-wrap"}}  dangerouslySetInnerHTML={{__html:rowQuestion.Content}}></div>
                    {showAbout===true && rowAbout !== null ? 
                        <Alert variant="info">
                            <div dangerouslySetInnerHTML={{__html:rowAbout?.Content}}></div>
                            {rowAbout?.Category?<h3 className="h5 text-primary mb-2">Kategoria : <span className="badge bg-primary text-white ms-2">{rowAbout?.Category}</span></h3>:null}
                            {rowAbout?.Source?.length>0?<>
                            <h3 className="h5 text-primary mb-2">Źródła</h3>
                            <ListGroup className="mb-4">
                                {rowAbout?.Source.map(function(item,idx){
                                    return <ListGroup.Item key={idx}>{item.Content} {item.Addon!==undefined&&item.Addon?.length!==undefined?(item.Addon?.length>0?item.Addon:null):null}</ListGroup.Item>
                                })}
                            </ListGroup>
                            </>
                            :null}
                            {rowAbout?.Tags?.length>0?<>
                                <h3 className="h5 text-primary mb-2">Tagi</h3>
                                <div className="mb-4">
                                    {rowAbout?.Tags.map(function(i,idx){
                                        return <Badge pill bg="primary" key={idx} className="me-2">{i.Name}</Badge>
                                    })}
                                </div>
                            </>:null}
                        </Alert>
                        :
                        null
                    }
        </>
}