import { Button, Col, Row } from "react-bootstrap";
import { Dispatch, SetStateAction, useState } from "react";
import { FaBars } from "react-icons/fa6";
import ExamLEKNav from "./Nav";
import ExamPageIndexMainTitle from "../Componets/ExamPageIndexMainTitle";
import ExamLEKPageCEM from "./PageCEM";
import ExamLEKPageOpened from "./PageOpened";

export default function ExamLEKPageIndex({activeTab}:{activeTab:string}){
    let [showNav,setShowNav]    = useState<boolean>(false);

    return <>
        <Row>
            <Col lg={2}>
                <ExamLEKNav activeTab={activeTab} setShow={setShowNav} show={showNav} />
            </Col>
            <Col lg={10} xs={12}>
                <Row>
                {activeTab==='otwarte'?<>
                        <ExamPageIndexMainTitle title="Otwarte egzaminy" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamLEKPageOpened />
                        </Col>
                    </>
                :null}
                {activeTab==='cem'?<>
                        <ExamPageIndexMainTitle title="Baza danych CEM" setShowNav={setShowNav} />
                        <Col xs={12}>
                            <ExamLEKPageCEM />
                        </Col>
                    </>
                :null}
                </Row>
            </Col>
        </Row>
    </>
}