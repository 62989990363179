import { useEffect,useContext, useRef } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {GlobalContext, GlobalReducerInitial, UserAccessListItem} from '../Componets/GlobalContext';
import { Table } from 'react-bootstrap';

function UserAccountForm(){
    const {globalState,globalDispatch} = useContext(GlobalContext);
    
    return (
    <Form>    
        <FloatingLabel controlId="fName" label="Nazwa użytkownika" className="mb-3">
            <Form.Control disabled={true} type="text" placeholder="moje-konto@domena.pl" name="Name" defaultValue={globalState.user && globalState.user.Name} />
        </FloatingLabel>
        <FloatingLabel controlId="fEmailAddress" label="Adres email..." className="mb-3">
            <Form.Control disabled={true} type="email" placeholder="moje-konto@domena.pl" name="EmailAddress" defaultValue={globalState.user && globalState.user.Email} />
        </FloatingLabel>
    </Form>
    )
}

function UserAccessList(){
    const {globalState,globalDispatch} = useContext<GlobalReducerInitial>(GlobalContext);

    // console.log( globalState.access. );
    // console.log(  typeof globalState.access );
    // let accessList = <UserAccessListItem[]>globalState.access;
    return <>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Specjalizacja</th><th>Data utworzenia</th><th>Data zakończenia</th>
                </tr>
            </thead>
            <tbody>
                {globalState.access?.map(function(i:UserAccessListItem,idx:number){
                    return <tr key={`userAccessItemRow-${idx}`}>
                        <td>{i.SpecName}</td>
                        <td>{i.Created}</td>
                        <td>{i.Expiration}</td>
                    </tr>
                })}
            </tbody>
        </Table>
    </>
}

export default function UserAccountPage(){
    useEffect(() => {
        document.title = 'ZdajLEKko.pl - Moje konto';
      }, []);
    return <>
        <h1 className="my-3 text-primary">Moje konto</h1>
        <UserAccountForm />
        <UserAccessList />
    </>;
}