import axios from "axios";
import { useContext, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Form, InputGroup, Row, ToggleButton } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { RecordGeneratorSession, RecordGeneratorV2Session } from "../Type/RecordGeneratorSession";
import { RecordGeneratorCategory } from "../Type/RecordGeneratorCategory";
import { RecordGeneratorTag } from "../Type/RecordGeneratorTag";
import DrawSpinner from "../Componets/DrawSpinner";
import GlobalContext, { UserAccessListItem } from "../Componets/GlobalContext";
import { RowSpec } from "../Type/RowSpec";
import { ExamPageGeneratorV2StepSource } from "./Generator/Source";
import { ExamPageGeneratorV2StepSpec } from "./Generator/Spec";
import { ExamPageGeneratorV2StepCategory } from "./Generator/Category";
import { ExamPageGeneratorV2StepSession } from "./Generator/Session";
import { ExamPageGeneratorV2StepTags } from "./Generator/Tag";
import { ExamPageGeneratorV2Sum } from "./Generator/Sum";

interface ExamGeneratorPageParams{
    usePES:boolean,
    useLIB:boolean,
    useSessions:number[],
    useCategories:number[],
    useTags:number[],
    precreateCount:number|null,
    inCreatingProgress:boolean,
    type:string,
    sessions:RecordGeneratorSession[]|null,
    categories:RecordGeneratorCategory[]|null,
    tags:RecordGeneratorTag[]|null
}

export interface ExamPageGeneratorV2State{
    page:string;
    questionCount:number|null;
    loading:boolean;

    // dicts
    userHasMoreSpecs:boolean;
    specList:RowSpec[]|null,
    sessions:RecordGeneratorV2Session[]|null,
    categories:RecordGeneratorCategory[]|null,
    tags:RecordGeneratorTag[]|null;

    sum:number|null;
    useCEM:boolean;
    useLib:boolean;
    specID:number[];
    sessionsChecks:number[];
    categoriesChecks:number[];
    tagsChecks:number[];
}


export function ExamPageGeneratorV2(){
    let [state,setState]    = useState<ExamPageGeneratorV2State>({useCEM:false,useLib:false,questionCount:null,
                                                                specID:[],sessionsChecks:[],page:'spec',
                                                                tags:null,tagsChecks:[],
                                                                categories:null,categoriesChecks:[],
                                                                loading:false,specList:null,userHasMoreSpecs:true,
                                                                sessions:null,sum:null});
    const {globalState}     = useContext(GlobalContext);
    let userSpecsList       = globalState.access as UserAccessListItem[];
    if (userSpecsList===undefined) return <></>

    // console.log(userSpecsList);
    if (state.page==='spec'){
        if (userSpecsList.length===1)
        {
            setState({...state,specID:[userSpecsList[0].SpecID!],page:'source',userHasMoreSpecs:false});
            return <></>
        }else{
            return <ExamPageGeneratorV2StepSpec state={state} setState={setState} />
        }
    }

    if (state.page==='source'){
        return <ExamPageGeneratorV2StepSource state={state} setState={setState} />
    }

    if (state.page==='session'){
        return <ExamPageGeneratorV2StepSession state={state} setState={setState} />
    }

    if (state.page==='categories'){
        return <ExamPageGeneratorV2StepCategory state={state} setState={setState} />
    }

    if (state.page==='tags'){
        return <ExamPageGeneratorV2StepTags state={state} setState={setState} />
    }

    if (state.page==='sum' || state.page==='create'){
        return <ExamPageGeneratorV2Sum state={state} setState={setState} />
    }


    return <>
    </>
}

function ExamPageGenerator() {
    let navigate            = useNavigate();
    let [params,setParams]  = useState<ExamGeneratorPageParams>({useLIB:false,usePES:false,useSessions:[],precreateCount:null,inCreatingProgress:false,
                                                                sessions:null,tags:null,useTags:[],type:'nauka',
                                                                categories:null,useCategories:[]});
    let isLoading           = useRef<boolean>(false);
    let inputName           = useRef<HTMLInputElement|null>(null);
    let inputType           = useRef<HTMLSelectElement|null>(null);

    let sesjeKK             = [45,6,3,42,41,43,44,46,1427];


    if (params.inCreatingProgress===false){
        if ( (params.usePES!==false) &&
             params.categories === null && params.sessions === null &&
             isLoading.current === false
            ){
                isLoading.current   = true;
                axios.post(`/generator/get-sessions`,{
                    usePES:params.usePES,
                    useLIB:params.useLIB
                }).then((response) => {
                    isLoading.current=false;
                    setParams({...params,sessions:response.data.data});
                }, (error) => {
                    isLoading.current=false;
                });
        }
    
        if ( (params.useLIB!==false || 
             (params.usePES!==false && params.sessions !==null 
                 /// && params.useSessions.length > 0    /// chyba bez tego?
             ) ) 
             &&
             params.categories === null &&
             isLoading.current === false
            ){
                isLoading.current   = true;
                axios.post(`/generator/get-categories`,{
                    usePES:params.usePES,
                    useLIB:params.useLIB,
                    useSessions:params.useSessions
                }).then((response) => {
                    isLoading.current=false;
                    setParams({...params,categories:response.data.data});
                }, (error) => {
                    isLoading.current=false;
                });
        }
    
        if ( (params.useLIB!==false || 
             (params.usePES!==false && params.sessions !== null ) ) 
            &&
            params.categories !== null &&
            params.tags === null &&
            isLoading.current === false
           ){
               isLoading.current   = true;
               axios.post(`/generator/get-tags`,{
                   usePES:params.usePES,
                   useLIB:params.useLIB,
                   useSessions:params.useSessions,
                   useCategories:params.useCategories
               }).then((response) => {
                   isLoading.current=false;
                   setParams({...params,tags:response.data.data});
               }, (error) => {
                   isLoading.current=false;
               });
       }
    
       if ( (params.useLIB!==false || 
            (params.usePES!==false && params.sessions !== null ) ) 
           &&
           params.categories !== null &&
           params.tags !== null &&
           params.precreateCount === null &&
           isLoading.current === false
          ){
              isLoading.current   = true;
              axios.post(`/generator/precreate`,{
                  usePES:params.usePES,
                  useLIB:params.useLIB,
                  useSessions:params.useSessions,
                  useCategories:params.useCategories,
                  useTags:params.useTags
              }).then((response) => {
                  isLoading.current=false;
                  setParams({...params,precreateCount:response.data.data});
              }, (error) => {
                  isLoading.current=false;
              });
      }
    }else{
        if (isLoading.current===false){
            isLoading.current   = true;
            let selectedType = inputType.current?inputType.current.options[inputType.current.selectedIndex].value:'nauka';
              axios.post(`/generator/create`,{
                  usePES:params.usePES,
                  useLIB:params.useLIB,
                  useSessions:params.useSessions,
                  useCategories:params.useCategories,
                  useTags:params.useTags,
                  type:selectedType,
                  name:inputName.current?inputName.current.value:null,
              }).then((response) => {
                  isLoading.current=false;
                  navigate(`/app/egzamin/${response.data.data.ID}/1`);
                //   setParams({...params,precreateCount:response.data.data});
              }, (error) => {
                  isLoading.current=false;
              });
        }
    }
    
    
        // console.log(params);
    
        function sessionOnClick(id:number){
            if (params.useSessions.some(i=>i===id))
                setParams({...params,precreateCount:null,categories:null,tags:null,useSessions:params.useSessions.filter(i=>i!==id)});
            else
                setParams({...params,precreateCount:null,categories:null,tags:null,useSessions:[...params.useSessions,id]});
        }

        function sessionSetKK(){
            setParams({...params,precreateCount:null,categories:null,tags:null,useSessions:sesjeKK});            
        }
    
        function sessionAllOnClick(){
            setParams({...params,precreateCount:null,categories:null,tags:null,useSessions:[]});
        }
    
        function categoryAllOnClick(){
            setParams({...params,precreateCount:null,useCategories:[],tags:null});
        }
    
        function tagAllOnClick(){
            setParams({...params,precreateCount:null,useTags:[]});
        }
    
        function categoryOnClick(id:number){
            if (params.useCategories.some(i=>i===id))
                setParams({...params,precreateCount:null,tags:null,useCategories:params.useCategories.filter(i=>i!==id)});
            else
                setParams({...params,precreateCount:null,tags:null,useCategories:[...params.useCategories,id]});
        }
    
        function tagOnClick(id:number){
            if (params.useTags.some(i=>i===id))
                setParams({...params,precreateCount:null,tags:null,useTags:params.useTags.filter(i=>i!==id)});
            else
                setParams({...params,precreateCount:null,tags:null,useTags:[...params.useTags,id]});
        }
    
        return <>
        <Helmet>
            <title>Stwórz własny egzamin - Generator</title>
        </Helmet>
            <Form>
                <Row>
                    <Col xs={12}>
                        <p>Egzamin utworzony za pomocą generatora może pomóc przy podsumowaniu nauki z konkretnego działu lub choroby.<br /> Jeśli uczyłeś się tylko <b>kardiologii</b> możesz wygenerować egzamin, który składa się wyłącznie z pytań z tej kategorii.</p>
                        
                        <ButtonGroup className="mb-5 d-flex">
                            <ToggleButton variant={params.usePES?'primary':'outline-primary'} className="flex-fill"
                                type="checkbox" id="dbPES" value="pes" checked={params.usePES} 
                                onClick={()=>setParams({...params,usePES:!params.usePES,categories:null,sessions:null,tags:null})}>
                                Baza danych pytań PES</ToggleButton>
                            <ToggleButton variant={params.useLIB?'primary':'outline-primary'} className="flex-fill"
                                type="checkbox" id="dbLIB" value="lib" checked={params.useLIB} 
                                onClick={()=>setParams({...params,useLIB:!params.useLIB,categories:null,sessions:null,tags:null})}>
                                Baza danych autorskich pytań</ToggleButton>
                        </ButtonGroup>
                     </Col>
                {params.sessions && params.sessions?.length > 0 ?
                    <>
                    <Col xs={12}>
                        <h2>Jakie sesje?</h2>
                        <div className="mb-5 d-flex flex-wrap">
                        <ToggleButton className="m-1 flex-fill" 
                                        variant={params.useSessions.length===0?'primary':'outline-primary'}
                                        type="checkbox" id="session-all" value={0} 
                                        checked={params.useSessions.length===0}
                                        onClick={()=>sessionAllOnClick()}>
                                        Wszystkie sesje</ToggleButton>
                        <ToggleButton className="m-1 flex-fill" 
                                        variant='outline-primary'
                                        type="checkbox" id="session-all" value={0} 
                                        onClick={()=>sessionSetKK()}>
                                        Sesje aktualna KK</ToggleButton>
                        {params.sessions.map(function(c,idx){
                            // let val=c.ID;
                            let isCheck = params.useSessions?.some(i=>i===c.ID);
                            return  <ToggleButton className="m-1 flex-fill" key={`sessionBtn-${c.ID}`} 
                                        variant={isCheck?'primary':'outline-primary'}
                                        type="checkbox" id={`session-${c.ID}`} value={c.ID} 
                                        checked={isCheck}
                                        onClick={()=>sessionOnClick(c.ID)}>
                                        {c.Year} - {c.Season} <span>({c.Total})</span></ToggleButton>
                            
                        })}
                        </div>
                    </Col>
                    </>
                    : null
                }
                {params.categories && params.categories?.length > 0 ?
                    <>
                    <Col xs={12}>
                        <h2>Jakie kategorie?</h2>
                        <div className="mb-5 d-flex flex-wrap">
                        <ToggleButton className="m-1 flex-fill" 
                                        variant={params.useCategories.length===0?'primary':'outline-primary'} 
                                        type="checkbox" id={`cat-all`} value={0}
                                        checked={params.useCategories.length===0}
                                        onClick={()=>categoryAllOnClick()}>
                                        Wszystkie kategorie</ToggleButton>
                        {params.categories.map(function(c,idx){
                            let isCheck = params.useCategories?.some(i=>i===c.ID);
                            return  <ToggleButton className="m-1 flex-fill" key={`catBtn-${c.ID}`} 
                                        variant={isCheck?'primary':'outline-primary'}
                                        type="checkbox" id={`cat-${c.ID}`} value={c.ID} 
                                        checked={isCheck}
                                        onClick={()=>categoryOnClick(c.ID)}>
                                        {c.Name} <span>({c.Total})</span></ToggleButton>
                            
                        })}
                        </div>
                    </Col>
                    </>
                    : null
                }
                {params.tags && params.tags?.length > 0 ?
                    <>
                    <Col xs={12}>
                        <h2>Jakie tagi?</h2>
                        <ToggleButton className="m-1" 
                                        variant={params.useTags.length===0?'primary':'outline-primary'} 
                                        type="checkbox" id={`tag-all`} value={0}
                                        checked={params.useTags.length===0}
                                        onClick={()=>tagAllOnClick()}>
                                        Wszystkie tagi</ToggleButton>
                        {params.tags.map(function(c,idx){
                            let isCheck = params.useTags?.some(i=>i===c.ID);
                            return  <ToggleButton className="m-1" key={`tagBtn-${c.ID}`} 
                                        variant={isCheck?'primary':'outline-primary'}
                                        type="checkbox" id={`tag-${c.ID}`} value={c.ID} 
                                        checked={isCheck}
                                        onClick={()=>tagOnClick(c.ID)}>
                                        {c.Name} <span>({c.Total})</span></ToggleButton>
                            
                        })}
                    </Col>
                    </>
                    : null
                }
                {params.tags !== null ?
                    <Col xs={12} className="mt-3 mb-5">
                        <InputGroup>
                            <Form.Select ref={inputType} disabled={params.inCreatingProgress} aria-label="Wybierz tryp" defaultValue={"nauka"}>
                                <option value="nauka">Tryb nauki</option>
                                <option value="egzamin">Tryb egzaminu</option>
                            </Form.Select>
                            <Form.Control maxLength={40} ref={inputName} disabled={params.inCreatingProgress} type="text" placeholder="Nazwa..." aria-label="Wprowadź nazwę dla egzaminu..." />
                            <Button disabled={params.inCreatingProgress || (params.precreateCount&&params.precreateCount>460)?true:false} 
                                onClick={()=>setParams({...params,inCreatingProgress:true})}>
                                <DrawSpinner show={params.inCreatingProgress} size="sm" />
                                Rozpocznij ({params.precreateCount} pytań)
                            </Button>
                        </InputGroup>
                    </Col>
                    :
                    null
                }
                </Row>
            </Form>
        </>
}

export default ExamPageGenerator;