import { Dispatch, SetStateAction, useState } from "react";
import { ExamPageGeneratorV2State } from "../PageGenerator";
import axios from "axios";
import { MdArrowLeft, MdArrowRight, MdCheck, MdCheckBoxOutlineBlank } from "react-icons/md";
import { Button, Col, Row } from "react-bootstrap";
import { RecordGeneratorV2Session } from "../../Type/RecordGeneratorSession";
import DrawSpinner from "../../Componets/DrawSpinner";


export function ExamPageGeneratorV2StepSessionDrawSession({session,state,setState}:{session:RecordGeneratorV2Session,state:ExamPageGeneratorV2State,setState:Dispatch<SetStateAction<ExamPageGeneratorV2State>>}){
    let isChecked = state.sessionsChecks.find(x=>x===session.ID) ? true : false;

    function onClick(){
        if (isChecked){
            setState({...state,sessionsChecks:state.sessionsChecks.filter(x=>x!==session.ID)});
        }else{
            setState({...state,sessionsChecks:[...state.sessionsChecks,session.ID]});
        }
    }

    return <>
        <Col xs={6} md={3} lg={2}>
            <Button variant={isChecked?'primary':'outline-primary'} className="w-100 mb-2 d-flex text-start align-items-center" onClick={onClick}>
                {isChecked ? <MdCheck size={24} /> : <MdCheckBoxOutlineBlank size={24} />}
                <span className="ms-2">
                    {session.Year} - {session.SeasonName}<br />
                    <small className="badge text-bg-secondary">{session.QuestionCount} pytań</small>
                </span>
            </Button>
        </Col>
    </>
}

function ExamPageGeneratorV2StepSessionDrawSum({state}:{state:ExamPageGeneratorV2State}){
    if (state.sessions){
        if (state.sessionsChecks.length===0)
            return <div className="alert alert-info pb-0"><p>Wybierz sesje, z których chcesz korzystać w generatorze.</p></div>
        
        let selected = state.sessions.reduce((sum,cur,idx)=>{
            if (state.sessionsChecks.find(s=>s===cur.ID))
                return sum + cur.QuestionCount;
            return sum;
        },0);
        return <div className="alert alert-info pb-0"><p><strong>{selected}</strong> pytań w zaznaczonych sesjach ({state.sessionsChecks.length}).</p></div>
    }
    return <></>
}

export function ExamPageGeneratorV2StepSession({state,setState}:{state:ExamPageGeneratorV2State,setState:Dispatch<SetStateAction<ExamPageGeneratorV2State>>}){
    let [loading,setLoading] = useState<boolean>(false);

    if (state.sessions===null){
        if (loading===false){
            setLoading(true);
            axios.post(`/generator-v2/get-sessions`,{
                useSpec:state.specID,
                useCEM:state.useCEM,
                useLIB:state.useLib
            }).then((response) => {
                setState({...state,sessions:response.data.data});
                setLoading(false);
            }, (error) => {
            });
        }

        return <><Row>
        <Col xs={12}>
            <div className="main-content">
                <DrawSpinner text="Pobieranie sesji...." />
            </div>
        </Col>
        </Row>
        </>
    }

    let backStep = 'source';
    //let sesjeKK             = [45,6,3,42,41,43,44,46,1427];
    // 95,100,57,56,90,1,91,92,93
    let newestSess      = [95,100,57,56,90,1,91,92,93];
    let subFilter       = state.sessionsChecks.filter(x=>newestSess.includes(x));
    let isNewestSess    = subFilter.length===newestSess.length;
    // let isCheckedNewest = state.sessionsChecks.find(x=>x===session.ID) ? true : false;

    function onClickNewest(){
        if (isNewestSess){
            setState({...state,questionCount:null,sessionsChecks:state.sessionsChecks.filter(x=>!newestSess.includes(x))});
        }else{
            setState({...state,questionCount:null,sessionsChecks:[...state.sessionsChecks,...newestSess.filter(x=>!state.sessionsChecks.includes(x))]});
        }
    }

    return <>
        <Row>
            <Col xs={12}>
                <div className="main-content">
                    <ExamPageGeneratorV2StepSessionDrawSum state={state} />
                    <Row>
                    <Col xs={6} md={3} lg={2}>
                        <Button variant={isNewestSess?'primary':'outline-primary'} className="w-100 mb-2 d-flex text-start align-items-center" onClick={onClickNewest}>
                            {isNewestSess ? <MdCheck size={24} /> : <MdCheckBoxOutlineBlank size={24} />}
                            <span className="ms-2">
                                Sesje od 2020 jesień
                                <small className="badge text-bg-secondary">Sesje obecnej KK MR</small>
                            </span>
                        </Button>
                    </Col>
                    {state.sessions.map(function(i){
                        return <ExamPageGeneratorV2StepSessionDrawSession key={`sessionCol-${i.ID}`} session={i} state={state} setState={setState} />
                    })}
                    </Row>

                    <div className="d-flex mt-3">
                        <Button variant="secoundary" onClick={()=>setState({...state,page:backStep})}><MdArrowLeft /> powrót</Button>
                        <Button className="flex-fill" disabled={state.sessionsChecks.length===0} 
                            onClick={() => setState({...state,page:'categories',categories:null,tags:null,tagsChecks:[],categoriesChecks:[]})}>Przejdź dalej <MdArrowRight /></Button>
                    </div>
                </div>
            </Col>
        </Row>
    </>
}