import axios from "axios";
import { Dispatch, SetStateAction, useState } from "react";
import { ExamPageGeneratorV2State } from "../PageGenerator";
import { MdArrowLeft, MdArrowRight, MdCheck, MdCheckBoxOutlineBlank } from "react-icons/md";
import { Alert, Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { RecordGeneratorTag } from "../../Type/RecordGeneratorTag";
import DrawSpinner from "../../Componets/DrawSpinner";

function ExamPageGeneratorV2StepTagsSum({state}:{state:ExamPageGeneratorV2State}){
    if (state.tags){
        if (state.tagsChecks.length===0)
            return <div className="alert alert-info pb-0"><p>Wybierz tagi, z których chcesz skorzystać w generatorze.</p></div>
        
        let selected = state.tags.reduce((sum,cur,idx)=>{
            if (state.tagsChecks.find(s=>s===cur.ID) !== undefined)
                return sum + cur.Total;
            return sum;
        },0);
        // let alertType = 'alert-info';
        // if (selected>460)
        //     alertType = 'alert-danger';
        return <div className={`alert alert-info pb-0`}><p><strong>{selected}</strong> pytań w zaznaczonych tagach ({state.tagsChecks.length}).</p>
                {/* {selected>460?<p><strong>Musisz ograniczyć liczbę pytań do 460.</strong></p>:null} */}
               </div>
    }
    return <></>
}

export function ExamPageGeneratorV2StepTagsDrawTag({tag,state,setState,size,filteredTags}:{size:'sm'|'lg'|undefined,tag:RecordGeneratorTag,filteredTags?:RecordGeneratorTag[],state:ExamPageGeneratorV2State,setState:Dispatch<SetStateAction<ExamPageGeneratorV2State>>}){
    let isChecked = state.tagsChecks.find(x=>x===tag.ID) !== undefined ? true : false;
    if (tag.ID===-1 && filteredTags){
        let filteredTagsSelected = state.tagsChecks.filter(x=>filteredTags.find(y=>y.ID===x));
        if (filteredTagsSelected.length === filteredTags.length)
            isChecked=true;
        else
            isChecked=false;
    }

    function onClick(){
        if (isChecked){
            if (tag.ID!==-1)
                setState({...state,questionCount:null,tagsChecks:state.tagsChecks.filter(x=>x!==tag.ID)});
            else{
                setState({...state,questionCount:null,tagsChecks:state.tagsChecks.filter(x=>!filteredTags?.find(t=>t.ID===x))});
            }
        }else{
            if (tag.ID!==-1)
                setState({...state,questionCount:null,tagsChecks:[...state.tagsChecks,tag.ID]});
            else{
                let filteredToAdd = filteredTags?.filter(x=>!state.tagsChecks.includes(x.ID))??[];
                let ids = filteredToAdd?.map(function(x){return x.ID;})??[];
                setState({...state,questionCount:null,tagsChecks:[...state.tagsChecks, ...ids]});
            }
        }
    }

    let checkSize = 24;
    if (size==='sm') checkSize=14;

    return <>
            <Button size={size} variant={isChecked?'primary':'outline-primary'} className="mb-2 d-flex text-start align-items-center me-2 flex-fill" onClick={onClick}>
                {isChecked ? <MdCheck size={checkSize} /> : <MdCheckBoxOutlineBlank size={checkSize} />}
                <span className="ms-2">
                    {tag.Name}
                    {size === 'sm' && tag.Total !== 0 ? <> ({tag.Total})</> : null}
                    {size===undefined ? <><br />
                        <small className="badge text-bg-secondary">{tag.Total} pytań</small></>:null}
                </span>
            </Button>
    </>
}


export function ExamPageGeneratorV2StepTags({state,setState}:{state:ExamPageGeneratorV2State,setState:Dispatch<SetStateAction<ExamPageGeneratorV2State>>}){
    let [loading,setLoading] = useState<boolean>(false);
    let [filter,setFilter]   = useState<string>('');

    if (state.tags===null){
        if (loading===false){
            setLoading(true);
            axios.post(`/generator-v2/get-tags`,{
                useSpec:state.specID,
                useSession:state.sessionsChecks,
                useCEM:state.useCEM,
                useLib:state.useLib,
                useCategories:state.categoriesChecks
            }).then((response) => {
                setState({...state,tags:response.data.data});
                setLoading(false);
            }, (error) => {
            });
        }

        return <><Row>
        <Col xs={12}>
            <div className="main-content">
                <DrawSpinner text="Pobieranie listy tagów...." />
            </div>
        </Col>
        </Row>
        </>
    }

    if (state.tags.length===0)
    {
        setState({...state,page:'sum'});
        return <></>
    }
    

    let tags = state.tags;
    let selected = null;
    if (filter?.length! > 0){
        tags  = state.tags.filter(x=>x.Name.toLowerCase().includes(filter.toLowerCase()));
        let catsIDs = tags.reduce<Number[]>((prev,cur,idx)=>{
            return  [...prev,cur.ID];
        },[]);
        
        selected    = state.tags.filter(x=>state.tagsChecks.includes(x.ID) && !catsIDs.includes(x.ID));
    }

    let filteredSum = tags.reduce((sum,cur,idx)=>{
        return sum + cur.Total;
    },0);

    // let disabledCreated = state.tagsChecks.length===0 || selectedSum>460;

    let backStep = 'categories';
    if (state.categories?.length===0){
        backStep = 'source';
        if (state.useCEM===true)
            backStep = 'session';
    }

    return <>
        <Row>
            <Col xs={12}>
                <div className="main-content">
                    <ExamPageGeneratorV2StepTagsSum state={state} />
                    <FloatingLabel controlId="tagsFilter" label="Filtruj... Czego szukasz?" className="mb-3">
                        <Form.Control type="text" placeholder="np. Radiologia" onChange={(e)=>setFilter(e.target.value!)} />
                    </FloatingLabel>
                    {tags.length === 0 ? <Alert className="alert alert-info"><p className="mb-0">Niestety nic nie pasuje do filtra.</p></Alert>:null}
                    <div className="d-flex flex-wrap">
                        {tags.length > 0 ?
                            <ExamPageGeneratorV2StepTagsDrawTag 
                                size={undefined} key={`tagCol-selAll`} tag={{ID:-1,Name:(filter?.length! > 0?'Zaznacz znalezione':'Zaznacz wszystko'),Total:filteredSum}} state={state} setState={setState} 
                                filteredTags={tags} />:null}
                    {tags.map(function(i){
                        return <ExamPageGeneratorV2StepTagsDrawTag size={undefined} key={`tagCol-${i.ID}`} tag={i} state={state} setState={setState} />
                    })}
                    </div>
                    {selected !== null && selected.length > 0 ? <>
                        <h2 className="mt-2 main-title d-flex">Zaznaczone</h2>
                        <div className="d-flex flex-wrap">
                        {selected.map(function(i){
                            return <ExamPageGeneratorV2StepTagsDrawTag size="sm" key={`tagCol-${i.ID}`} tag={i} state={state} setState={setState} />
                        })}
                        </div>
                    </>:null}

                    <div className="d-flex mt-3">
                        {state.userHasMoreSpecs ? 
                            <Button variant="secoundary" onClick={()=>setState({...state,page:backStep})}><MdArrowLeft /> powrót</Button>
                        :null}
                        <Button className="flex-fill" disabled={(state.useCEM===false && state.useLib===false)} 
                            onClick={() => setState({...state,page:'sum'})}>Przejdź dalej <MdArrowRight /></Button>
                    </div>
                </div>
            </Col>
        </Row>
    </>
}