import { useState } from "react";
import { RowExamNew } from "../Type/RowExamNew";
import { RowUserExamQuestion } from "../Type/RowUserExamQuestion";
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MdCheck } from "react-icons/md";
import { MdDangerous } from "react-icons/md";
import Spinner from 'react-bootstrap/Spinner';
import { RecordQuestionDescription } from "../Type/RecordQuestionDescription";
import { Alert } from "react-bootstrap";

export function QuestionAnswerList({rowQuestion,rowExam,rowQuestionAbout,showDescription}:{rowQuestion:RowUserExamQuestion,rowExam:RowExamNew,rowQuestionAbout:null|RecordQuestionDescription,showDescription:boolean}) {
    let [questionUserAnswer,setQuestionUserAnswer] = useState<string|null>(rowQuestion.Answer);
    let [questionAnswerSaved,setQuestionAnswerSaved] = useState<boolean>(false);
    let [isAnswerSubmit,setIsAnswerSubmit] = useState<boolean>(false);
    let navigate = useNavigate();

    if (rowQuestion.Answer!==null){
        return (
            <>
                <ListGroup variant="flush" key="list-answer">
                {rowQuestion.Options?.map(function(item,idx){
                    let questionAbout = rowQuestionAbout?.Options.find(i => i.Letter == item.Letter);
                    if (questionUserAnswer===item.Letter && questionUserAnswer!=rowQuestion.CorrectAnswer)
                        return <ListGroup.Item variant="danger" className="fs-5" key={`answer-item${item.ID}`}>
                                <span className="text-primary fw-bold">{item.Letter}.</span> <span dangerouslySetInnerHTML={{__html:item.Content}}></span> <MdDangerous />
                                {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
                                    <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
                                    : null
                                }
                            </ListGroup.Item>;
                    else if (questionUserAnswer===item.Letter && questionUserAnswer==rowQuestion.CorrectAnswer)
                        return <ListGroup.Item variant="success" className="fs-5" key={`answer-item${item.ID}`}>
                                <span className="text-primary fw-bold">{item.Letter}.</span> <span dangerouslySetInnerHTML={{__html:item.Content}}></span> <MdCheck />
                                {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
                                    <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
                                    : null
                                }
                            </ListGroup.Item>
                    else if (item.Letter==rowQuestion.CorrectAnswer)
                        return <ListGroup.Item variant="success" className="fs-5" key={`answer-item${item.ID}`}>
                                <span className="text-primary fw-bold">{item.Letter}.</span> <span dangerouslySetInnerHTML={{__html:item.Content}}></span>
                                {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
                                    <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
                                    : null
                                }
                            </ListGroup.Item>
                    else
                        return <ListGroup.Item className="fs-5" key={`answer-item${item.ID}`}>
                                <span className="text-primary fw-bold">{item.Letter}.</span> <span dangerouslySetInnerHTML={{__html:item.Content}}></span>
                                {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
                                    <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
                                    : null
                                }
                            </ListGroup.Item>
                })}
                </ListGroup>
            </>
        );
    }

    if (questionUserAnswer!==null && questionAnswerSaved===false&&isAnswerSubmit===false){
        setIsAnswerSubmit(true);
        axios.post(`/egzamin/submit-user-exam-question-answer/${rowQuestion.ID}`,
            {UserAnswer:questionUserAnswer}
        ).then((response) => {
            setQuestionAnswerSaved(true);
            setIsAnswerSubmit(false);
            if (rowExam.Type==='egzamin'){
                navigate(`/app/egzamin/${rowExam.ID}/${rowQuestion.NextOrder}`);
            }
        }, (error) => {
            setIsAnswerSubmit(false);
        });

    }

    // if (questionUserAnswer!==null && rowExam.Type==='egzamin'&&rowQuestion.NextOrder!==null){
    //     // navigate()
    // }

    // if (questionUserAnswer!==null && rowExam.Type==='nauka'){
    //     return (
    //         <>
    //             <ListGroup variant="flush">
    //             {rowQuestion.Options?.map(function(item,idx){
    //                 if (questionUserAnswer===item.Letter && questionUserAnswer!=rowQuestion.CorrectAnswer)
    //                     return <ListGroup.Item variant="danger" className="fs-5" key={item.ID}><span className="text-primary fw-bold">{item.Letter}.</span> {item.Content} <MdDangerous /></ListGroup.Item>;
    //                 else if (questionUserAnswer===item.Letter && questionUserAnswer==rowQuestion.CorrectAnswer)
    //                     return <ListGroup.Item variant="success" className="fs-5" key={item.ID}><span className="text-primary fw-bold">{item.Letter}.</span> {item.Content} <MdCheck /></ListGroup.Item>;
    //                 else if (item.Letter==rowQuestion.CorrectAnswer)
    //                     return <ListGroup.Item variant="success" className="fs-5" key={item.ID}><span className="text-primary fw-bold">{item.Letter}.</span> {item.Content}</ListGroup.Item>;
    //                 else
    //                     return <ListGroup.Item className="fs-5" key={item.ID}><span className="text-primary fw-bold">{item.Letter}.</span> {item.Content}</ListGroup.Item>;
    //             })}
    //             </ListGroup>
    //         </>
    //     );
    // }

    return (
        <>
            <ListGroup variant="flush">
            {rowQuestion.Options?.map(function(item,idx){
                let questionAbout = rowQuestionAbout?.Options.find(i => i.Letter == item.Letter);
                if (questionUserAnswer===item.Letter)
                    return <ListGroup.Item disabled={isAnswerSubmit} action variant="light" onClick={() => setQuestionUserAnswer(item.Letter)} className="fs-5" key={item.ID}>
                            <span className="text-primary fw-bold">{item.Letter}.</span> {item.Content}
                            {isAnswerSubmit === true ? <Spinner animation="border" role="status" size="sm" className='mx-5'><span className="visually-hidden">Loading...</span></Spinner> : null }
                            {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
                                <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
                                : null
                            }
                           </ListGroup.Item>
                else
                    return <ListGroup.Item disabled={isAnswerSubmit} action onClick={() => setQuestionUserAnswer(item.Letter)} className="fs-5" key={item.ID}>
                            <span className="text-primary fw-bold">{item.Letter}.</span> {item.Content}
                            {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
                                <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
                                : null
                            }
                           </ListGroup.Item>
            })}
            </ListGroup>
        </>
    )
}

export default QuestionAnswerList;