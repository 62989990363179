import { Alert, Button, ListGroup } from "react-bootstrap";
import { RowExamNew } from "../Type/RowExamNew";
import { RowUserExamQuestionOption } from "../Type/RowUserExamQuestionOption";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { RowUserExamQuestion } from "../Type/RowUserExamQuestion";
import { MdCheck, MdCleanHands, MdDangerous } from "react-icons/md";
import { RecordQuestionOptionDescription } from "../Type/RecordQuestionDescription";
import { stateQuestion } from "./Question/State";
import { stateUserAnswerReset } from "./QuestionOptionsList";
import DrawSpinner from "./DrawSpinner";

export function QuestionOptionDescriptionDisplay({showDescription,optionDescription}:{showDescription:boolean,optionDescription:RecordQuestionOptionDescription|null|undefined}){
    return <>
        {optionDescription !== null && optionDescription?.Content !== null && optionDescription !== undefined && showDescription === true ?
            <Alert variant="info mt-2"><div dangerouslySetInnerHTML={{__html:optionDescription.Content}}></div></Alert>
            : null
        }
    </>
}

function QuestionOption({rowExam,questionState,rowOption,onAnswerClick,isAnswerSubmiting,isDisabled,currentAnswer,showDescription,optionDescription,setResetUserAnswer,resetUserAnswer}:
        {rowExam:RowExamNew,questionState:stateQuestion,rowOption:RowUserExamQuestionOption,onAnswerClick:MouseEventHandler<HTMLElement>,isAnswerSubmiting:boolean,isDisabled:boolean,currentAnswer:string|null,showDescription:boolean,optionDescription:RecordQuestionOptionDescription|null|undefined,
            setResetUserAnswer?:Dispatch<SetStateAction<stateUserAnswerReset>>,
            resetUserAnswer?:stateUserAnswerReset
        })
{
    let rowQuestion = questionState.rowQuestion;
    if (rowQuestion===null) return <></>

    if (rowExam.Type==='egzamin' && currentAnswer){
        return <ListGroup.Item variant={currentAnswer===rowOption.Letter?'info':'default'} className="fs-5" key={rowOption.ID}>
                    <span className="text-primary fw-bold">{rowOption.Letter}.</span> <span dangerouslySetInnerHTML={{__html:rowOption.Content}}></span>
                    <QuestionOptionDescriptionDisplay showDescription={showDescription} optionDescription={optionDescription} />
               </ListGroup.Item>
    }

    if (isAnswerSubmiting){
        return <ListGroup.Item variant={currentAnswer===rowOption.Letter?'info':'default'} className="fs-5" key={rowOption.ID}>
                <span className="text-primary fw-bold">{rowOption.Letter}.</span> <span dangerouslySetInnerHTML={{__html:rowOption.Content}}></span>
                <QuestionOptionDescriptionDisplay showDescription={showDescription} optionDescription={optionDescription} />
               </ListGroup.Item>
    }

    if (isDisabled){
        return <ListGroup.Item variant={currentAnswer===rowOption.Letter?
                    (currentAnswer===rowQuestion.CorrectAnswer?'success':'danger')
                    :
                    (rowOption.Letter===rowQuestion.CorrectAnswer?'success':'default')} className="fs-5" key={rowOption.ID}>
                <span className="text-primary fw-bold">{rowOption.Letter}.</span> <span dangerouslySetInnerHTML={{__html:rowOption.Content}}></span>
                {currentAnswer===rowOption.Letter?(currentAnswer===rowQuestion.CorrectAnswer?<MdCheck />:<MdDangerous />):null}
                {rowExam.Type!=='egzamin' && currentAnswer===rowOption.Letter && setResetUserAnswer !== undefined?
                    <Button onClick={()=>setResetUserAnswer({...resetUserAnswer!,reset:true})} disabled={resetUserAnswer?.loading??false} size="sm" className="ms-2" variant="outline-primary" >
                        <DrawSpinner show={resetUserAnswer?.loading??false} />
                        <MdCleanHands size="16" /> Usuń odpowiedź</Button>
                    :null}
                <QuestionOptionDescriptionDisplay showDescription={showDescription} optionDescription={optionDescription} />
               </ListGroup.Item>
    }

    return <>
        <ListGroup.Item action onClick={onAnswerClick} className="fs-5" key={rowOption.ID}>
            <span className="text-primary fw-bold">{rowOption.Letter}.</span> <span dangerouslySetInnerHTML={{__html:rowOption.Content}}></span>
            <QuestionOptionDescriptionDisplay showDescription={showDescription} optionDescription={optionDescription} />
        </ListGroup.Item>
    </>
}

export default QuestionOption;