import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { RecordGeneratorCategory } from "../../Type/RecordGeneratorCategory";
import { ExamPageGeneratorV2State } from "../PageGenerator";
import { Dispatch, SetStateAction, useState } from "react";
import { MdArrowLeft, MdArrowRight, MdCheck, MdCheckBoxOutlineBlank } from "react-icons/md";
import axios from "axios";
import DrawSpinner from "../../Componets/DrawSpinner";



export function ExamPageGeneratorV2StepCategoryDrawCategory({category,state,setState,size}:{size:'sm'|'lg'|undefined,category:RecordGeneratorCategory,state:ExamPageGeneratorV2State,setState:Dispatch<SetStateAction<ExamPageGeneratorV2State>>}){
    let isChecked = false;
    if (category.ID!==-1)
        isChecked = state.categoriesChecks.find(x=>x===category.ID) !== undefined ? true : false;
    else
        isChecked = state.categoriesChecks?.length === state.categories?.length;
    
    function onClick(){
        if (category.ID!==-1){
            if (isChecked){
                setState({...state,questionCount:null,categoriesChecks:state.categoriesChecks.filter(x=>x!==category.ID)});
            }else{
                setState({...state,questionCount:null,categoriesChecks:[...state.categoriesChecks,category.ID]});
            }
        }else{
            if (isChecked){
                setState({...state,questionCount:null,categoriesChecks:[]});
            }else{
                let categories = state.categories?.map(function(i){return i.ID;})??[];
                setState({...state,questionCount:null,categoriesChecks:categories})
            }
        }
    }

    let checkSize = 24;
    if (size==='sm') checkSize=14;

    return <>
            <Button size={size} variant={isChecked?'primary':'outline-primary'} className="mb-2 d-flex text-start align-items-center me-2 flex-fill" onClick={onClick}>
                {isChecked ? <MdCheck size={checkSize} /> : <MdCheckBoxOutlineBlank size={checkSize} />}
                <span className="ms-2">
                    {category.Name} 
                    {size === 'sm' && category.Total !== 0 ? <> ({category.Total})</> : null}
                    {size===undefined && category.Total !== 0 ? <><br />
                        <small className="badge text-bg-secondary">{category.Total} pytań</small></>:null}
                </span>
            </Button>
    </>
}


function ExamPageGeneratorV2StepCategorySum({state}:{state:ExamPageGeneratorV2State}){
    if (state.categories){
        if (state.categoriesChecks.length===0)
            return <div className="alert alert-info pb-0"><p>Wybierz kategorie, z których chcesz skorzystać w generatorze.</p></div>
        
        let selected = state.categories.reduce((sum,cur,idx)=>{
            if (state.categoriesChecks.find(s=>s===cur.ID)!==undefined)
                return sum + cur.Total;
            return sum;
        },0);
        return <div className="alert alert-info pb-0"><p><strong>{selected}</strong> pytań w zaznaczonych kategoriach ({state.categoriesChecks.length}).</p></div>
    }
    return <></>
}

export function ExamPageGeneratorV2StepCategory({state,setState}:{state:ExamPageGeneratorV2State,setState:Dispatch<SetStateAction<ExamPageGeneratorV2State>>}){
    let [loading,setLoading] = useState<boolean>(false);
    let [filter,setFilter]   = useState<string>('');

    if (state.categories===null){
        if (loading===false){
            setLoading(true);
            axios.post(`/generator-v2/get-categories`,{
                useSpec:state.specID,
                useSession:state.sessionsChecks,
                useCEM:state.useCEM,
                useLib:state.useLib
            }).then((response) => {
                setState({...state,categories:response.data.data});
                setLoading(false);
            }, (error) => {
            });
        }

        return <><Row>
        <Col xs={12}>
            <div className="main-content">
                <DrawSpinner text="Pobieranie kategorii...." />
            </div>
        </Col>
        </Row>
        </>
    }
    

    if (state.categories.length===0)
    {
        setState({...state,page:'tags',tags:null});
        return <></>
    }

    let categories = state.categories;
    let selected = null;
    if (filter?.length! > 0){
        categories  = state.categories.filter(x=>x.Name.toLowerCase().includes(filter.toLowerCase()));
        let catsIDs = categories.reduce<Number[]>((prev,cur,idx)=>{
            return  [...prev,cur.ID];
        },[]);
        
        selected    = state.categories.filter(x=>state.categoriesChecks.includes(x.ID) && !catsIDs.includes(x.ID));
    }

    let backAction = 'session';
    if (state.useCEM===false && state.useLib===true)
        backAction='source';

    return <>
        <Row>
            <Col xs={12}>
                <div className="main-content">
                    <ExamPageGeneratorV2StepCategorySum state={state} />
                    <FloatingLabel controlId="categoryFilter" label="Filtruj... Czego szukasz?" className="mb-3">
                        <Form.Control type="text" placeholder="np. Radiologia" onChange={(e)=>setFilter(e.target.value!)} />
                    </FloatingLabel>

                    <div className="d-flex flex-wrap">
                    {filter===''?
                        <ExamPageGeneratorV2StepCategoryDrawCategory size={undefined} key="categoryCol-CheckAll" category={{ID:-1,Name:'Zaznacz wszystkie',Total:0}} state={state} setState={setState} />
                        :null}
                    {categories.map(function(i){
                        return <ExamPageGeneratorV2StepCategoryDrawCategory size={undefined} key={`categoryCol-${i.ID}`} category={i} state={state} setState={setState} />
                    })}
                    </div>
                    {selected !== null && selected.length > 0 ? <>
                        <h2 className="mt-2 main-title d-flex">Zaznaczone</h2>
                        <div className="d-flex flex-wrap">
                        {selected.map(function(i){
                            return <ExamPageGeneratorV2StepCategoryDrawCategory size="sm" key={`categoryCol-${i.ID}`} category={i} state={state} setState={setState} />
                        })}
                        </div>
                    </>:null}

                    <div className="d-flex mt-3">
                        <Button variant="secoundary" onClick={()=>setState({...state,page:backAction})}><MdArrowLeft /> powrót</Button>
                        <Button className="flex-fill" disabled={(state.sessionsChecks.length===0&&state.useCEM)} 
                            onClick={() => setState({...state,page:'tags',tags:null,tagsChecks:[]})}>Przejdź dalej <MdArrowRight /></Button>
                    </div>
                </div>
            </Col>
        </Row>
    </>
}