import { Helmet } from 'react-helmet-async';
import { useLoaderData, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RowExamNew } from "../Type/RowExamNew";
import { Alert, Button, Placeholder } from "react-bootstrap";
import QuestionLoadingPlaceholder from "../Componets/QuestionLoadingPlaceholder";
import axios from "axios";
import { RowExamIndex } from "../Type/RowExamIndex";
import { FaCheck, FaClock, FaXmark } from "react-icons/fa6";
import GlobalContext from "../Componets/GlobalContext";
import { MdEdit } from "react-icons/md";
import Question from '../Componets/Question';

function MyExamPagePlaceholder(){
    return <>
        <Helmet><title>Mój egzamin...</title></Helmet>
        <h1 className="my-3 text-primary">
            <Placeholder animation="glow">
                <Placeholder xs={30} />
            </Placeholder>
        </h1>
        <QuestionLoadingPlaceholder />
    </>
}


function MyExamPagePaginatorButton({examType,correctAnswer,userAnswer,isActive,order,examID}:{examType:string|null,correctAnswer:string,userAnswer:string|null,isActive:boolean,order:number,examID:number}){
    let navigate = useNavigate();

    if (examType==='egzamin')
    {
        if (correctAnswer==='X')
            return <Button active={isActive} size="sm" className="m-1" variant="outline-warning" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        if (userAnswer===null)
            return <Button active={isActive} size="sm" className="m-1" variant="outline-light" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        else
            return <Button active={isActive} size="sm" className="m-1" variant="dark" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
    }
    else
    {
        if (correctAnswer==='X')
            return <Button active={isActive} size="sm" className="m-1" variant="outline-warning" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        if (userAnswer===null)
            return <Button active={isActive} size="sm" className="m-1" variant="outline-light" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        else{
            // console.log(correctAnswer+'-'+userAnswer);
            if (correctAnswer===userAnswer)
                return <Button active={isActive} size="sm" className="m-1" variant="success" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
            else
                return <Button active={isActive} size="sm" className="m-1" variant="danger" onClick={() => navigate(`/app/egzamin/${examID}/${order}`)} key={`page-${order}`}>{order}</Button>
        }
    }
}

function MyExamPagePaginator({examID,current,rowExam,examIndexData}:{examID:number,current:number,rowExam:RowExamNew,examIndexData:Array<RowExamIndex>|null}){
    if (examIndexData===null)return <></>;
    return <>
        {/* <Pagination className="text-center justify-content-center"> */}
            {/* <Pagination.First title="Skok do pierwszego pytania" onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${firstQuestion.Order}`)} /> */}
            {examIndexData.map(function(item,idx){
                return <MyExamPagePaginatorButton key={`paginator-btn-${item.Order}`}
                                                  examType={rowExam.Type} 
                                                  examID={examID} order={item.Order}
                                                  userAnswer={item.Answer} correctAnswer={item.CorrectAnswer}
                                                  isActive={current===item.Order} />
                // let type = item.Answer===item.CorrectAnswer?'outline-success':'outline-danger';
                // if (rowExam.Type=='egzamin'){
                //     if (item.Answer)
                //         type = 'outline-info';
                //     else
                //         type = 'outline-default';
                // }
                    
                // if (current===item.Order)
                //     type = item.Answer===item.CorrectAnswer?'success':'danger';
                // return <Button size="sm" className="m-1" variant={type} onClick={() => navigate(`/app/egzamin/${examID}/${item.Order}`)} key={`page-${idx}`}>
                //     {item.Order} {item.Answer===item.CorrectAnswer?<FaCheck />:<FaXmark />}
                // </Button>;
            })}
            {/* <Pagination.Last title="Skok do ostatniego pytania" onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${lastQuestion.Order}`)} /> */}
        {/* </Pagination> */}
    </>
}

function ExamTimer({rowExam,onStopClick,onClock}:{rowExam:RowExamNew,onStopClick:(state: boolean) => void|null,onClock:(clock:number)=>void|null }){
    const [stop,setStop]            = useState<boolean>(false);
    const [clock,setClock]          = useState<number>(0);

    useEffect(()=>{
        if (!stop){
            const interval = setInterval(()=>{
                setClock(clock+1);
                if (onClock)
                    onClock(clock+1);
            },1000);
            return () => clearInterval(interval);
        }
    },[clock,stop]);

    if (rowExam.Type!=='egzamin'){
        setStop(true);
        return <></>;
    }

    let sec = clock % 60;
    let min = (clock - sec) % 60;
    let hou = (clock - sec - min * 60) % 60;

    function stopClick(state:boolean){
        setStop(state);
        if (onStopClick)
            onStopClick(state);
    }

    return <Alert variant="info" className="ms-auto align-self-center p-1">
        <FaClock className="me-2" />
        {(hou.toString()).padStart(2,'0')}:{(min.toString().padStart(2,'0'))}:{(sec.toString()).padStart(2,'0')}
        <Button className="ms-2" size="sm" onClick={()=>stopClick(!stop)}>Stop!</Button>
    </Alert>
}

export default function MyExamPage(){
    let isLoading                                   = useRef<boolean>(false);
    let [ examData, setExamData ]                   = useState<RowExamNew|null>(null);
    let [ hideQuestion, setHideQuestion ]           = useState<boolean>(false);
    const [examIndexData,setExamIndexData]          = useState<Array<RowExamIndex>|null>(null);
    let { examID, questionID }                      = useParams();
    let navigate                                    = useNavigate();

    if (examData===null&&isLoading.current===false){
        isLoading.current=true;
        axios.get(`/egzamin/get-user-exam/${examID}`).then((response) => {
            isLoading.current=false;
            setExamData(response.data.data);
          }, (error) => {
            isLoading.current=false;
        });

        return <MyExamPagePlaceholder />
    }
    
    if (examIndexData===null){
        axios.get(`/egzamin/get-exam-index/${examID}`).then((response) => {
            setExamIndexData(response.data.data);
        }, (error) => {
            // console.log(error);
        });
        return <MyExamPagePlaceholder />
    }

    if (examData===null) return <MyExamPagePlaceholder />

    if (!questionID){
        return <>
            <Helmet>
                <title>{examData.Name}</title>
            </Helmet>
            <h1 className="my-3 text-primary">{examData.Name}</h1>
            <Button size="lg" onClick={() => navigate(`/app/egzamin/${examID}/1`)}>Rozpocznij</Button>
            </>
    }

    function setQuestionOptionOnIndexData(option:string|null,question:number){
        // console.log('Option - '+option);
        // console.log('Question - '+question);
        if (option===null) return;
        let curidx = examIndexData?.findIndex(x=>x.Order===question);
        // if (!curidx)return;
        let indexCopy = examIndexData!;
        // console.log(indexCopy[curidx!].Answer);
        indexCopy[curidx!].Answer = option===''?null:option;
        // console.log(indexCopy[curidx!].Answer);
        // let current = examIndexData?.find(x=>x.Order===question);
        // if (!current) return;
        // let indexCopy = examIndexData?.filter(x=>x.Order!==question);
        // if (!indexCopy) return;
        // current.Answer = option;
        // indexCopy?.push(current);
        setExamIndexData(indexCopy);
    }

    let clockState = null;
    function onClockState(clock:number){
        clockState=clock;
    }

    return <>
    <Helmet>
        <title>{examData.Name}</title>
    </Helmet>
    <div className="d-flex align-items-center">
        <h1 className="my-3 text-primary">{examData.Name}</h1>
        {/* <ExamTimer rowExam={examData} onStopClick={(state) => setHideQuestion(state)} onClock={onClockState}/> */}
    </div>
    <Question lockQuestion={hideQuestion} questionID={questionID} exam={examData} onOptionClick={(option) => setQuestionOptionOnIndexData(option,parseInt(questionID!)) } />
    <MyExamPagePaginator examID={examData.ID} current={parseInt(questionID)} rowExam={examData} examIndexData={examIndexData} />
    </>;
}