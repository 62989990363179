import axios from "axios";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Button, Card, FloatingLabel, Form, Offcanvas } from "react-bootstrap";
import { FaReply } from "react-icons/fa6";
import DrawSpinner from "../Componets/DrawSpinner";

interface UserCommentRecord{
    ID:number;
    QuestionID:number;
    ParentID:number|null;
    UserID:number;
    UserName:string;
    Created:EpochTimeStamp,
    Updated:EpochTimeStamp|null;
    Content:string;
    Childs:UserCommentRecord[]|null;
}

function ExamQuestionCommentItem({comment,subComments,setReply,depth}:
    {comment:UserCommentRecord,subComments:UserCommentRecord[]|null|undefined,depth:number,setReply:(parent:number)=>void|null})
{
    return <>
        <div className={depth===0?'mb-1':'mb-1'}>
            <div className="d-flex align-items-center mb-1">
                <div className=" flex-grow-1"><b>{comment.UserName}</b> - <small>{comment.Created}</small></div>
                {depth===0?<Button onClick={()=>setReply(comment.ID)} className="align-self-end" size="sm" variant="outline-primary">odpowiedz <FaReply /></Button>:null}
            </div>
            <div className="comment-content" dangerouslySetInnerHTML={{__html:comment.Content}} />
        </div>
        {subComments !== null ? <div className="mb-3 ms-3"> {subComments?.map(function(i){
            return <ExamQuestionCommentItem key={`comment-${i.ID}`} comment={i} setReply={setReply} depth={1} subComments={null} />
        })} </div> :null}
    </>
}

function ExamQuestionComment({questionID,show,setShow}:{questionID:number,show:boolean,setShow:Dispatch<SetStateAction<boolean>>}){
    let [edit,setEdit]              = useState<boolean>(false);
    let [comments,setComments]      = useState<Array<UserCommentRecord>|null>(null);
    let [submit,setSubmit]          = useState<boolean>(false);
    let parentComment               = useRef<HTMLInputElement>(null);
    let userComment                 = useRef<HTMLTextAreaElement>(null);
    let isSubmiting                 = useRef<boolean>(false);

    if (show===false) return <></>

    if (comments===null && isSubmiting.current===false){
        isSubmiting.current=true;
        axios.post(`/pytanie/komentarze`,{
            id:questionID
        }).then((r)=>{
            isSubmiting.current=false;
            setComments(r.data.data);
        }, (e) => {
            isSubmiting.current=false;
        });
    }

    // console.log(submit);
    if (submit&&isSubmiting.current===false){
        isSubmiting.current=true;
        // console.log(submit);
        // console.log({questionID:questionID,
        //     parentComment:parentComment.current?.value,
        //     content:userComment.current?.value});
        if ((userComment.current?.value?.length??0)>0){
            axios.post(`/pytanie/komentarz`,{
                questionID:questionID,
                parentComment:parentComment.current?.value,
                content:userComment.current?.value
            }).then((response) => {
                isSubmiting.current=false;
                setSubmit(false);
                if (parentComment.current)
                    parentComment.current.value="";
                if (userComment.current)
                    userComment.current.value="";
                let newItem = response.data.data as UserCommentRecord;
                // let 
                setComments([...comments??[],newItem]);
                // setState({...state,templateCategories:response.data.data.categories,templateSessions:response.data.data.sessions});
            }, (error) => {
                isSubmiting.current=false;
                setSubmit(false);
            });
        }else{
            isSubmiting.current=false;
            setSubmit(false);
        }
    }

    function setEditForm(id:number|null){
        setEdit(true);
        userComment.current?.focus();
        if (id!==null){
            if (parentComment.current)
                parentComment.current.value    = id.toString();
        }
    }

    // console.log(userComment.current?.value);
    // console.log(userComment.current?.value);

    return <>
        <Offcanvas show={show} onHide={()=>setShow(false)} placement="bottom" className="h-50" keyboard={false} scroll={true} backdrop={false}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Dyskusja</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form className="mb-4" autoComplete="off">
                    <input type="hidden" defaultValue={questionID} name="QuestionID" />
                    <input type="hidden" ref={parentComment} name="ParentCommentID" />
                    <FloatingLabel controlId="commentContent" label="Twój komentarz">
                        <Form.Control disabled={submit} ref={userComment} autoComplete="off" as="textarea" placeholder="Dołącz do dysuksji na temat pytania..."
                            onFocus={()=>setEditForm(null)}
                        // style={{height:(edit?'100px':'auto')}}
                         />
                    </FloatingLabel>
                    {
                        edit ? <div className="my-2 d-grid"><Button disabled={submit} variant="primary" onClick={()=>setSubmit(true)}>
                            <DrawSpinner show={submit} /> Dodaj
                            </Button></div> : null
                    }
                </Form>
                {
                    comments !== null ? comments.filter(x=>x.ParentID===null).map(
                        function(i){
                            return <ExamQuestionCommentItem key={`comment-${i.ID}`} setReply={setEditForm} comment={i} depth={0} subComments={comments!.filter(x=>x.ParentID===i.ID)} /> 
                        } ) 
                    : null
                }
                {/* <div className="mb-1">
                    <div className="d-flex align-items-center mb-1">
                        <div className=" flex-grow-1"><b>Nazwa usera</b> - <small>2024-11-09 00:15:63</small></div>
                        <Button onClick={()=>setEditForm(null)} className="align-self-end" size="sm" variant="outline-primary">odpowiedz <FaReply /></Button>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus faucibus mauris id iaculis. Etiam eros dui, facilisis eu diam eget, pretium faucibus leo. Donec ut volutpat purus. Aliquam erat volutpat. Sed ut risus fringilla sem rutrum sagittis sagittis eget nunc. Sed et sem ac tortor dictum semper. Etiam dapibus, mi sit amet posuere efficitur, dolor tortor vulputate velit, at hendrerit massa ipsum sit amet enim. Proin tempus sem sit amet congue viverra. Etiam ut ante magna. Aliquam enim lectus, imperdiet eget erat a, laoreet porttitor orci. Sed cursus quam eu dolor consectetur, ut porta dui rhoncus.</p>
                </div>
                <div className="mb-1 ms-4">
                    <div className="d-flex align-items-center mb-1">
                        <div className=" flex-grow-1"><b>User 2133</b> - <small>2024-11-09 00:15:63</small></div>
                        <Button onClick={()=>setEditForm(null)} className="align-self-end" size="sm" variant="outline-primary">odpowiedz <FaReply /></Button>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus faucibus mauris id iaculis. Etiam eros dui, facilisis eu diam eget, pretium faucibus leo. Donec ut volutpat purus. Aliquam erat volutpat. Sed ut risus fringilla sem rutrum sagittis sagittis eget nunc. Sed et sem ac tortor dictum semper. Etiam dapibus, mi sit amet posuere efficitur, dolor tortor vulputate velit, at hendrerit massa ipsum sit amet enim. Proin tempus sem sit amet congue viverra. Etiam ut ante magna. Aliquam enim lectus, imperdiet eget erat a, laoreet porttitor orci. Sed cursus quam eu dolor consectetur, ut porta dui rhoncus.</p>
                </div> */}
            </Offcanvas.Body>
        </Offcanvas>
      </>
}

export default ExamQuestionComment;