import { createContext } from "react";
import { UserIdentity } from "../Type/UserIdentity";
import { useCookies } from "react-cookie";
 
export const GlobalReducer = (state:any,  action:any) => {
    // const [cookies, setCookie, removeCookie] = useCookies(['siteTheme']);
    // console.log(state);
    if (action.type==='ADD_MESSAGES'&&state.flashMessages.length===0)
        action.type='SET_MESSAGES';
    switch(action.type) {
       case 'CLEAR_MESSAGES':
        return {...state,flashMessages:[]};
       case 'SET_MESSAGES':
        return {...state, flashMessages : [ ...action.flashMessages ] }
       case 'ADD_MESSAGES':
        return {...state, flashMessages : [...state.flashMessages, ...action.flashMessages]}
       case 'REMOVE_MESSAGE':
        return {...state, flashMessages : state.flashMessages.filter((i:any) => i.time !== action.removeTime ) }
       case 'SET_USER':
        return {...state, user:action.user,access:action.access};
       case 'SWITCH_THEME':
         return {...state, siteTheme:!(state.siteTheme??false)};
       case 'SET_THEME':
          return {...state, siteTheme:action.value};
      default:
        return state
    }
}

export interface AppMessage {
    type:string;
    text:string;
    time:number;
}

export class UserAccessListItem {
    Created?:string;
    Expiration?:string;
    SpecID?:number;
    SpecName?:string;
}

export class GlobalReducerInitial {
    globalState?:any;
    globalDispatch?:any;
    flashMessages? : AppMessage[];
    user?:UserIdentity;
    access?:UserAccessListItem[];
    siteTheme?:boolean;
};

export const GlobalReducerInitialState = new GlobalReducerInitial();

export const GlobalContext = createContext<GlobalReducerInitial>(GlobalReducerInitialState);
export default GlobalContext;