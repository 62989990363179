import Alert from 'react-bootstrap/Alert';
import { useContext, useEffect } from "react";
import GlobalContext, { AppMessage } from '../Componets/GlobalContext';

function FlashMessages() {
    const {globalState,flashMessages,globalDispatch} = useContext(GlobalContext);

    useEffect( () => { 
      fetch('/messages',{
        method:'GET',mode:'cors',
        headers: {
          'Accept': 'application/json',
          "X-Requested-With": "XMLHttpRequest",
        }}).then(response => response.json())
          .then(data => {
            if (data.messages!==undefined)
              globalDispatch({type:'SET_MESSAGES',flashMessages:data.messages});
          })
          .catch(error => console.error(error));
    },[] );

    // console.log(globalState);
    // console.log(flashMessages);
    // console.log(globalState.flashMessages);
    if (!globalState.flashMessages) return <></>
    if (!globalState.flashMessages?.length) return <></>

    // if (!globalState) return <></>
    // if (!globalState.flashMessages) return <></>

    return (
        <div id="flash-messages" className="mt-2">
          {globalState.flashMessages?.map((message:AppMessage) => (
            <Alert onClose={() => globalDispatch({type:'REMOVE_MESSAGE',removeTime:message.time})}
                   key={message.type+message.time} 
                   variant={message.type} 
                   dismissible>
                    <p className="m-0">{message.text}</p>
            </Alert>
          ))}
        </div>
      );
}

export default FlashMessages;