import { Dispatch, SetStateAction, useRef, useState } from "react";
import { RowExamNew } from "../Type/RowExamNew";
import { RowUserExamQuestion } from "../Type/RowUserExamQuestion";
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MdCheck } from "react-icons/md";
import { MdDangerous } from "react-icons/md";
import Spinner from 'react-bootstrap/Spinner';
import { RecordQuestionDescription, RecordQuestionOptionDescription } from "../Type/RecordQuestionDescription";
import { Alert } from "react-bootstrap";
import QuestionOption from "./QuestionOption";
import { stateQuestion } from "./Question/State";

export interface stateUserAnswerReset{
    reset:boolean;
    loading:boolean;
}

export function QuestionOptionsList({questionState,rowExam,rowOptionsAbout,showDescription,setRowQuestionData,onOptionClick}:
        {questionState:stateQuestion,rowExam:RowExamNew,rowOptionsAbout:undefined|null|RecordQuestionOptionDescription[],showDescription:boolean,setRowQuestionData:Dispatch<SetStateAction<stateQuestion>>,
            onOptionClick:(option: string) => void | null;
        }) 
{
    let rquestion = questionState.rowQuestion as RowUserExamQuestion;
    let [questionUserAnswer,setQuestionUserAnswer]      = useState<string|null>(rquestion.Answer?rquestion.Answer:null);
    let [resetUserAnswer,setResetUserAnswer]            = useState<stateUserAnswerReset>({reset:false,loading:false});
    let [userAnswerSubmiting,setUserAnswerSubmiting]    = useState<boolean>(false);
    let [userAnswerSaved,setUserAnswerSaved]            = useState<boolean>(false);
    let navigate = useNavigate();

    if (questionState.rowQuestion===null) return <></>

    if (questionUserAnswer!==null && rquestion?.Answer === null && userAnswerSubmiting===false){
        setUserAnswerSubmiting(true);
        axios.post(`/egzamin/submit-user-exam-question-answer/${rquestion?.ID}`,
            {UserAnswer:questionUserAnswer}
        ).then((response) => {
            // setTimeout(function(){
                setUserAnswerSubmiting(false);
                setRowQuestionData({...questionState, rowQuestion:{ ...rquestion, Answer:questionUserAnswer }});
                if (onOptionClick!==null && questionUserAnswer!==null){
//                    let index
                    onOptionClick(questionUserAnswer);
                }
            if (rowExam.Type==='egzamin'){
                if (rquestion?.NextOrder!==null&&rquestion?.NextOrder!==undefined)
                    navigate(`/app/egzamin/${rowExam.ID}/${rquestion?.NextOrder}`);
            }else{
                setQuestionUserAnswer(questionUserAnswer);  // rerender???
            }
            // }},4000)
        }, (error) => {
            setUserAnswerSubmiting(false);
            setQuestionUserAnswer(questionUserAnswer);  // rerender???
        });
    }

    if (resetUserAnswer.reset===true && rowExam.Type!=='egzamin'){
        if(resetUserAnswer.loading===false){
            setResetUserAnswer({...resetUserAnswer,loading:true});
            axios.post(`/egzamin/reset-user-exam-question-answer/${rquestion?.ID}`)
            .then((r)=>{
                setResetUserAnswer({reset:false,loading:false});
                setRowQuestionData({...questionState, rowQuestion:{ ...rquestion, Answer:null }});
                setQuestionUserAnswer(null);
                onOptionClick('');
            }, (e)=>{
                setResetUserAnswer({reset:false,loading:false});
            });
        }
    }


    // console.log({submiting:userAnswerSubmiting});
    return <>
        {rquestion.CorrectAnswer==='X'?<Alert variant="danger" className="my-4"><MdDangerous size={64} /> <span className="fs-5">Pytanie niezgodne z aktualną wiedzą medyczną. Nie ma prawidłowej odpowiedzi.</span></Alert>:null}
        <ListGroup variant="flush" className={userAnswerSubmiting===true?"position-relative":''}>
            {userAnswerSubmiting===true? <>
                <div className="position-absolute top-0 end-0 w-100 h-100 z-2 bg-dark-subtle opacity-25"></div>
                <div className="position-absolute top-50 start-50 translate-middle z-3"><Spinner key="question-submit-loader" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>
                </>
                :null}
            {rquestion?.Options?.map(function(item,idx){
                return <QuestionOption 
                        rowExam={rowExam} 
                        questionState={questionState}
                        rowOption={item} 
                        key={item.ID} 
                        resetUserAnswer={resetUserAnswer}
                        setResetUserAnswer={setResetUserAnswer}
                        currentAnswer={questionUserAnswer}
                        optionDescription={rowOptionsAbout?.find(o=>o.Letter===item.Letter)}
                        showDescription={showDescription}
                        isDisabled={rquestion?.Answer!==null || rquestion.CorrectAnswer==='X' ?true:false}
                        isAnswerSubmiting={userAnswerSubmiting}
                        onAnswerClick={() => setQuestionUserAnswer(item.Letter)} 
                    />
            })}
        </ListGroup>
    </>
//     let [questionUserAnswer,setQuestionUserAnswer] = useState<string|null>(rowQuestion.Answer);
//     let [questionAnswerSaved,setQuestionAnswerSaved] = useState<boolean>(false);
//     let [isAnswerSubmit,setIsAnswerSubmit] = useState<boolean>(false);
//     let navigate = useNavigate();

//     if (rowQuestion.Answer!==null){
//         return (
//             <>
//                 <ListGroup variant="flush">
//                 {rowQuestion.Options?.map(function(item,idx){
//                     let questionAbout = rowQuestionAbout?.Options.find(i => i.Letter == item.Letter);
//                     if (questionUserAnswer===item.Letter && questionUserAnswer!=rowQuestion.CorrectAnswer)
//                         return <>
//                             <ListGroup.Item variant="danger" className="fs-5" key={item.ID}><span className="text-primary fw-bold">{item.Letter}.</span> {item.Content} <MdDangerous />
//                             {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
//                                 <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
//                                 : null
//                             }
//                             </ListGroup.Item>
//                         </>;
//                     else if (questionUserAnswer===item.Letter && questionUserAnswer==rowQuestion.CorrectAnswer)
//                         return <>
//                             <ListGroup.Item variant="success" className="fs-5" key={item.ID}><span className="text-primary fw-bold">{item.Letter}.</span> {item.Content} <MdCheck />
//                             {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
//                                 <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
//                                 : null
//                             }
//                             </ListGroup.Item>
//                         </>
//                     else if (item.Letter==rowQuestion.CorrectAnswer)
//                         return <>
//                             <ListGroup.Item variant="success" className="fs-5" key={item.ID}><span className="text-primary fw-bold">{item.Letter}.</span> {item.Content}
//                             {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
//                                 <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
//                                 : null
//                             }
//                             </ListGroup.Item>
//                         </>
//                     else
//                         return <>
//                             <ListGroup.Item className="fs-5" key={item.ID}><span className="text-primary fw-bold">{item.Letter}.</span> {item.Content}
//                             {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
//                                 <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
//                                 : null
//                             }
//                             </ListGroup.Item>
//                         </>
//                 })}
//                 </ListGroup>
//             </>
//         );
//     }

//     if (questionUserAnswer!==null && questionAnswerSaved===false&&isAnswerSubmit===false){
//         setIsAnswerSubmit(true);
//         axios.post(`/egzamin/submit-user-exam-question-answer/${rowQuestion.ID}`,
//             {UserAnswer:questionUserAnswer}
//         ).then((response) => {
//             setQuestionAnswerSaved(true);
//             setIsAnswerSubmit(false);
//             if (rowExam.Type==='egzamin'){
//                 navigate(`/app/egzamin/${rowExam.ID}/${rowQuestion.NextOrder}`);
//             }
//         }, (error) => {
//             setIsAnswerSubmit(false);
//         });

//     }

//     return (
//         <>
//             <ListGroup variant="flush">
//             {rowQuestion.Options?.map(function(item,idx){
//                 let questionAbout = rowQuestionAbout?.Options.find(i => i.Letter == item.Letter);
//                 if (questionUserAnswer===item.Letter)
//                     return <ListGroup.Item disabled={isAnswerSubmit} action variant="light" onClick={() => setQuestionUserAnswer(item.Letter)} className="fs-5" key={item.ID}>
//                             <span className="text-primary fw-bold">{item.Letter}.</span> {item.Content}
//                             {isAnswerSubmit === true ? <Spinner animation="border" role="status" size="sm" className='mx-5'><span className="visually-hidden">Loading...</span></Spinner> : null }
//                             {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
//                                 <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
//                                 : null
//                             }
//                            </ListGroup.Item>
//                 else
//                     return <ListGroup.Item disabled={isAnswerSubmit} action onClick={() => setQuestionUserAnswer(item.Letter)} className="fs-5" key={item.ID}>
//                             <span className="text-primary fw-bold">{item.Letter}.</span> {item.Content}
//                             {questionAbout !== null && questionAbout?.Content !== null && questionAbout !== undefined && showDescription === true ?
//                                 <Alert variant="info"><div dangerouslySetInnerHTML={{__html:questionAbout.Content}}></div></Alert>
//                                 : null
//                             }
//                            </ListGroup.Item>
//             })}
//             </ListGroup>
//         </>
//     );
}

export default QuestionOptionsList;