import { Dispatch, SetStateAction, useState } from "react";
import { RecordCategory } from "../Type/RecordCategory";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DrawSpinner from "../Componets/DrawSpinner";
import QuestionPreview from "../Question/Preview";
import { BrowseIndexShowNavBtn } from "./Browse";
import { Nav } from "react-bootstrap";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { RecordTag } from "../Type/RecordTag";
import { RecordSource } from "../Type/RecordSource";
import { RecordGeneratorV2Session } from "../Type/RecordGeneratorSession";

interface browseIds{
    QuestionID:number|null;
    NextQuestionID:number|null;
    PrevQuestionID:number|null;
    Position:number|null;
}

interface stateBrowseCategoryPreview{
    category:RecordCategory|null;
    loading:boolean;
    ids:browseIds|null;
}

interface stateBrowseTagPreview{
    tag:RecordTag|null;
    loading:boolean;
    ids:browseIds|null;
}

interface stateBrowseSourcePreview{
    source:RecordSource|null;
    loading:boolean;
    ids:browseIds|null;
}

interface stateBrowseSessionPreview{
    session:RecordGeneratorV2Session|null;
    loading:boolean;
    ids:browseIds|null;
}

function BrowsePreviewResult({setShowNav,title,QuestionID,nextUrl,prevUrl}:{setShowNav:Dispatch<SetStateAction<boolean>>,title:string,QuestionID:number,prevUrl:string,nextUrl:string}){
    let navigate                    = useNavigate();
    return <>
        <div className="main-title">
            <BrowseIndexShowNavBtn setShowNav={setShowNav} />
            <h1>{title}</h1>
        </div>
        <QuestionPreview QuestionID={QuestionID} showNowComments={false} showProposalBtn={true} />
        <Nav fill variant="underline" className="mt-3">
            {prevUrl!==''?
                <Nav.Item><Nav.Link onClick={()=>navigate(prevUrl)}><MdArrowBack /> poprzednie pytanie</Nav.Link></Nav.Item>
                :
                <Nav.Item><Nav.Link disabled={true}><MdArrowBack /> poprzednie pytanie</Nav.Link></Nav.Item>
            }
            {nextUrl!==''?
                <Nav.Item><Nav.Link onClick={()=>navigate(nextUrl)}>kolejne pytanie <MdArrowForward /></Nav.Link></Nav.Item>
                :
                <Nav.Item><Nav.Link disabled={true}>kolejne pytanie <MdArrowForward /></Nav.Link></Nav.Item>
            }
        </Nav>
    </>
}

export function BrowseCategoryPreview({setShowNav}:{setShowNav:Dispatch<SetStateAction<boolean>>}){
    let navigate                    = useNavigate();
    let [state,setState]            = useState<stateBrowseCategoryPreview>({category:null,loading:false,ids:null});
    let {SpecID,ID,QuestionID}      = useParams();

    if (QuestionID){
        if (parseInt(QuestionID)!==state.ids?.QuestionID && state.category!==null){
            setState({...state,ids:null,category:null});
            return <></>
        }
    }


    if (state.category===null&&state.loading===false){
        setState({...state,loading:true});
        axios.post(`/browse/category`,{SpecID:SpecID,ID:ID,QuestionID:QuestionID}).then((r)=>{
            if (r.data.data)
                setState({...state,category:r.data.data,loading:false,ids:r.data.state});
            else
                throw new Error('Nie znaleziono kategorii.');
        });
    }

    if (state.category===null){
        return <>
            <div className="main-title">
                <BrowseIndexShowNavBtn setShowNav={setShowNav} />
                <h1>Pytania z kategorii... <DrawSpinner text="" /></h1>
            </div>
        </>
    }

    let nextUrl = '';
    let prevUrl = '';
    if (state.ids?.NextQuestionID && state.ids)
        nextUrl = `/app/przegladaj/kategorie/${SpecID}/${state.category.ID}/${state.ids?.NextQuestionID}`
    if (state.ids?.PrevQuestionID && state.ids)
        prevUrl = `/app/przegladaj/kategorie/${SpecID}/${state.category.ID}/${state.ids?.PrevQuestionID}`

    return <BrowsePreviewResult 
        title={`Pytania z kategorii ${state.category?.Name} (${state.ids?.Position}/${state.category.Count})`}
        setShowNav={setShowNav}
        QuestionID={state.ids?.QuestionID!}
        prevUrl={prevUrl}
        nextUrl={nextUrl}
     />
}



export function BrowseTagPreview({setShowNav}:{setShowNav:Dispatch<SetStateAction<boolean>>}){
    let navigate                    = useNavigate();
    let [state,setState]            = useState<stateBrowseTagPreview>({tag:null,loading:false,ids:null});
    let {SpecID,ID,QuestionID}      = useParams();

    if (QuestionID){
        if (parseInt(QuestionID)!==state.ids?.QuestionID && state.tag!==null){
            setState({...state,ids:null,tag:null});
            return <></>
        }
    }


    if (state.tag===null&&state.loading===false){
        setState({...state,loading:true});
        axios.post(`/browse/tag`,{SpecID:SpecID,ID:ID,QuestionID:QuestionID}).then((r)=>{
            if (r.data.data)
                setState({...state,tag:r.data.data,loading:false,ids:r.data.state});
            else
                throw new Error('Nie znaleziono tagu.');
        });
    }

    if (state.tag===null){
        return <>
            <div className="main-title">
                <BrowseIndexShowNavBtn setShowNav={setShowNav} />
                <h1>Pytania z tagu... <DrawSpinner text="" /></h1>
            </div>
        </>
    }

    let nextUrl = '';
    let prevUrl = '';
    if (state.ids?.NextQuestionID && state.ids)
        nextUrl = `/app/przegladaj/tagi/${SpecID}/${state.tag.ID}/${state.ids?.NextQuestionID}`
    if (state.ids?.PrevQuestionID && state.ids)
        prevUrl = `/app/przegladaj/tagi/${SpecID}/${state.tag.ID}/${state.ids?.PrevQuestionID}`

    return <BrowsePreviewResult 
        title={`Pytania z tagu ${state.tag?.Name} (${state.ids?.Position}/${state.tag.Count})`}
        setShowNav={setShowNav}
        QuestionID={state.ids?.QuestionID!}
        prevUrl={prevUrl}
        nextUrl={nextUrl}
     />
}




export function BrowseSourcePreview({setShowNav}:{setShowNav:Dispatch<SetStateAction<boolean>>}){
    let navigate                    = useNavigate();
    let [state,setState]            = useState<stateBrowseSourcePreview>({source:null,loading:false,ids:null});
    let {SpecID,ID,QuestionID}      = useParams();

    if (QuestionID){
        if (parseInt(QuestionID)!==state.ids?.QuestionID && state.source!==null){
            setState({...state,ids:null,source:null});
            return <></>
        }
    }


    if (state.source===null&&state.loading===false){
        setState({...state,loading:true});
        axios.post(`/browse/source`,{SpecID:SpecID,ID:ID,QuestionID:QuestionID}).then((r)=>{
            if (r.data.data)
                setState({...state,source:r.data.data,loading:false,ids:r.data.state});
            else
                throw new Error('Nie znaleziono źródła.');
        });
    }

    if (state.source===null){
        return <>
            <div className="main-title">
                <BrowseIndexShowNavBtn setShowNav={setShowNav} />
                <h1>Pytania z źródła... <DrawSpinner text="" /></h1>
            </div>
        </>
    }

    let nextUrl = '';
    let prevUrl = '';
    if (state.ids?.NextQuestionID && state.ids)
        nextUrl = `/app/przegladaj/zrodla/${SpecID}/${state.source.ID}/${state.ids?.NextQuestionID}`
    if (state.ids?.PrevQuestionID && state.ids)
        prevUrl = `/app/przegladaj/zrodla/${SpecID}/${state.source.ID}/${state.ids?.PrevQuestionID}`

    return <BrowsePreviewResult 
        title={`Pytania z ${state.source?.Name} (${state.ids?.Position}/${state.source.Count})`}
        setShowNav={setShowNav}
        QuestionID={state.ids?.QuestionID!}
        prevUrl={prevUrl}
        nextUrl={nextUrl}
     />
}


export function BrowseSessionPreview({setShowNav}:{setShowNav:Dispatch<SetStateAction<boolean>>}){
    let navigate                    = useNavigate();
    let [state,setState]            = useState<stateBrowseSessionPreview>({session:null,loading:false,ids:null});
    let {SpecID,ID,QuestionID}      = useParams();

    if (QuestionID){
        if (parseInt(QuestionID)!==state.ids?.QuestionID && state.session!==null){
            setState({...state,ids:null,session:null});
            return <></>
        }
    }


    if (state.session===null&&state.loading===false){
        setState({...state,loading:true});
        axios.post(`/browse/session`,{SpecID:SpecID,ID:ID,QuestionID:QuestionID}).then((r)=>{
            if (r.data.data)
                setState({...state,session:r.data.data,loading:false,ids:r.data.state});
            else
                throw new Error('Nie znaleziono sesji.');
        });
    }

    if (state.session===null){
        return <>
            <div className="main-title">
                <BrowseIndexShowNavBtn setShowNav={setShowNav} />
                <h1>Pytania z sesji... <DrawSpinner text="" /></h1>
            </div>
        </>
    }

    let nextUrl = '';
    let prevUrl = '';
    if (state.ids?.NextQuestionID && state.ids)
        nextUrl = `/app/przegladaj/sesje/${SpecID}/${state.session.ID}/${state.ids?.NextQuestionID}`
    if (state.ids?.PrevQuestionID && state.ids)
        prevUrl = `/app/przegladaj/sesje/${SpecID}/${state.session.ID}/${state.ids?.PrevQuestionID}`

    return <BrowsePreviewResult 
        title={`Pytania z ${state.session?.Year} - ${state.session.SeasonName} (${state.ids?.Position}/${state.session.QuestionCount})`}
        setShowNav={setShowNav}
        QuestionID={state.ids?.QuestionID!}
        prevUrl={prevUrl}
        nextUrl={nextUrl}
     />
}