import axios from "axios";
import DrawSpinner from "../Componets/DrawSpinner";
import { RowExamNew } from "../Type/RowExamNew";
import { RowExamResult } from "../Type/RowExamResult";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, ButtonGroup, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../Componets/GlobalContext";
import { FaCheck, FaPencil } from "react-icons/fa6";
import * as formik from 'formik';
import * as yup from 'yup';
import { MdCancel, MdCheck, MdFastRewind, MdPlayArrow, MdRefresh, MdStart } from "react-icons/md";

interface StateExamPageOpened {
    current: RowExamNew[]|null,
    currentList: RowExamResult[]|null,
    editRow:number|null,
    closeModal:true|false,
    saving:boolean;
    refreshExam:number|null;
    refresh:boolean;
}

function ExamPageOpened(){
    const {globalState,globalDispatch} = useContext(GlobalContext);
    // const [edit,setEdit]               = useState<number|null>(null);
    // const [closeModal,setCloseModal]   = useState<boolean>(false);
    let [state,setState] = useState<StateExamPageOpened>({current:null,currentList:null,editRow:null,closeModal:false,saving:false,refreshExam:null,refresh:false});
    let navigate         = useNavigate();
    let rowEdit          = state?.currentList?.find(x=>x.ID===state.editRow);
    // console.log(rowEdit);

    const schemaEditRow = yup.object({
        examID: yup.number().required(),
        examName: yup.string().required('Nazwa jest wymagana.').max(128,'Nazwa nie moze być dłuższa niż 128 znaków.'),
    });
    
    const f = formik.useFormik({
        validationSchema:schemaEditRow,
        onSubmit(values, formikHelpers) {
            setState({...state,saving:true});
            if (state.saving===false){
                axios.post(`/egzamin/nazwa`,values).then((r)=>{
                    let newList = state.currentList!.map(function(x){
                        if (x.ID===r.data.data.ID)
                            return r.data.data;
                        return x;
                    });
                    setState({...state,saving:false,currentList:newList,editRow:null});
                }, (e) => {
                });
            }
        },
        initialValues:{
            examID: 0,
            examName: '',
        }
    });

    if (f.values.examID!==rowEdit?.ID && rowEdit)
        f.setValues({examID:rowEdit.ID!,examName:rowEdit.Name!});

    if (!globalState.user?.HasAccess){
        return <></>
    }

    if (state?.currentList===null){
        axios.post('/egzamin/get-last-exams',{id:null}).then((r)=>{
            setState({
                ...state,
                currentList:r.data.data.current
            });
        }).catch((e)=>{

        });
        return <>
            <Helmet>
                <title>Otwarte egzaminy - ZdajLEKko</title>
            </Helmet>
            <DrawSpinner text="Pobieranie listy otwartych egzaminów..." />
        </>
    }

    if (state.refresh===true && state.refreshExam!==null&&state.saving===false){
        setState({...state,saving:true});
            axios.post('/egzamin/wyczysc',{id:state.refreshExam}).then((r)=>{
                let newList = state.currentList!.map(function(x){
                    if (x.ID===r.data.data.ID)
                        return r.data.data;
                    return x;
                });
                setState({...state,saving:false,currentList:newList,refresh:false,refreshExam:null});
            }).catch((e)=>{

            });
    }

    function showCloseModal(){
        if (f.values.examName!==rowEdit?.Name){
            setState({...state,closeModal:true});
        }else{
            setState({...state,editRow:null});
        }
    }

    function resetEditRowAndCloseModal(){
        setState({...state,closeModal:false,editRow:null});
        f.setValues({examID:0,examName:''});
    }


    let renderList  = null;
    if (state.currentList && state.currentList.length>0){
        renderList = state.currentList.map(function(i,k){
            return <tr key={`egzamin-${i.ID}`}>
                <td>
                    {i.ID!==state.editRow ? 
                    <>
                        {i.Name} 
                        <Button size="sm" variant="default" onClick={()=>setState({...state,editRow:i.ID})}><FaPencil /></Button>
                    </> :
                    <>
                    <Form noValidate onSubmit={f.handleSubmit}>
                        <Form.Control type="hidden" value={i.ID} name="examID" />
                        <InputGroup>
                            <Form.Control size="sm" type="text" name="examName" value={f.values.examName!} onChange={f.handleChange} isValid={f.touched.examName&&!f.errors.examName} isInvalid={f.touched.examName&&f.errors.examName?true:false} />
                            <Button disabled={state.saving} variant="outline-primary" type="submit" id="rowSave" size="sm">
                                <DrawSpinner show={state.saving} />
                                <FaCheck />
                            </Button>
                            <Button variant="outline-primary" id="rowCancel" size="sm" onClick={showCloseModal}><MdCancel /></Button>
                            <Form.Control.Feedback type="invalid">{f.errors.examName}</Form.Control.Feedback>
                        </InputGroup>
                    </Form>
                    </>
                    }
                </td>
                <td className="txt-c">{i.Type}</td>
                <td className="txt-c">{i.Position!>0?<>{i.Position}/{i.QuestionCount}</>:'-'}</td>
                <td>{i.AnswerCount > 0 ? <ButtonGroup aria-label="Opcje egzaminu">
                        <Button size='sm' variant="primary" className="btn-tl" onClick={() => navigate(`/app/egzamin/${i.ID}/${i.Position}`)}><MdPlayArrow /> <span>Kontynuuj</span></Button>
                        <Button size="sm" variant="warning" className="btn-tl" onClick={() => setState({...state,refreshExam:i.ID})}><MdRefresh /> <span>Wyzeruj</span></Button>
                    </ButtonGroup>
                : <Button size='sm' variant="primary" className="btn-tl" onClick={() => navigate(`/app/egzamin/${i.ID}/1`)}><MdPlayArrow /> <span>Rozpocznij</span></Button> }</td>
            </tr>
        }); 
    }

    let refreshRow = state.currentList.find(x=>x.ID===state.refreshExam);

    return <>
        <Helmet>
            <title>Otwarte egzaminy - ZdajLEKko</title>
        </Helmet>
        <Table striped bordered hover responsive size="sm">
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th className="txt-c">Tryb</th>
                    <th className="txt-c">Postęp</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {renderList!==null ? renderList : <tr><td colSpan={4}>Brak otwartych egzaminów.</td></tr>}
            </tbody>
        </Table>
        <Modal show={state.closeModal} onHide={()=>setState({...state,closeModal:false})}>
            <Modal.Header closeButton>
                <Modal.Title>Czy na pewno chcesz porzucić zmiany?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setState({...state,closeModal:false})}>Powrót</Button>
                <Button variant="primary" onClick={resetEditRowAndCloseModal}>Porzuć</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={state.refreshExam!==null} onHide={()=>setState({...state,refresh:false,refreshExam:null})}>
            <Modal.Header closeButton>
                <Modal.Title>Czy na pewno chcesz usunąć postęp?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Czy na pewno chcesz usunąć postęp dla <b>{refreshRow?.Name}</b>?</p>
                <p>Wszystkie Twoje odpowiedzi i czasy zostaną usunięte na tym egzaminie.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={state.refresh} variant="secondary" onClick={()=>setState({...state,refresh:false,refreshExam:null})}>Nie</Button>
                <Button disabled={state.refresh} variant="primary" onClick={()=>setState({...state,refresh:true})}>
                    <DrawSpinner show={state.saving} />
                    Tak
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default ExamPageOpened;