import axios from "axios";
import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { MdFavorite, MdHeartBroken } from "react-icons/md";
import DrawSpinner from "../../Componets/DrawSpinner";

export interface stateQuestionFavoriteButton{
    saveFavorite:boolean;
    isFavorite:boolean;
};

export function QuestionFavoriteButton({QuestionID,FavoriteID}:{QuestionID:number,FavoriteID:number|null}){
    let [favorite,setFavorite]  = useState<stateQuestionFavoriteButton>({saveFavorite:false,isFavorite:FavoriteID?true:false});
    let isSubmiting             = useRef<boolean>(false);

    if (favorite.saveFavorite && isSubmiting.current===false){
        isSubmiting.current=true;
        axios.post('/pytanie/favorite',{id:QuestionID}).then((r) => {
            isSubmiting.current=false;
            setFavorite({saveFavorite:false,isFavorite:r.data.data});
          }, (error) => {
            isSubmiting.current=false;
        });
    }

    return <>
        <Button disabled={isSubmiting.current} variant={favorite.isFavorite?'primary':'outline-primary'} size="sm" onClick={() => setFavorite({...favorite,saveFavorite:true})}>
            <DrawSpinner size="sm" show={isSubmiting.current} />
            {favorite.isFavorite?
                <><MdFavorite /> Lubisz! </>
            :
                <><MdHeartBroken /> Lubisz<span className="d-none d-md-inline"> to pytanie</span>?</>
            }
        </Button>
    </>
}