import { useNavigate } from "react-router-dom";

function checkResponse(status:number){
    // let navigation = useNavigate();
    if (status===401){
        window.location.pathname = '/';
        return true;
    }
    return false;
}

export default checkResponse;