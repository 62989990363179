import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FlashMessages from '../Helpers/Messages';

function AppContainer({children}) {
    // function onCopyHandle(e){
    //   // console.log(e);
    //   e.preventDefault();
    // }

    return (
      // <Container fluid onCopy={onCopyHandle} onSelect={onCopyHandle} onSelectCapture={onCopyHandle} className="d-print-none">
        <Container fluid onCopy={(e)=>e.preventDefault()}  className="d-print-none">
        <Row>
          <Col xs={12}><FlashMessages /></Col>
          {/* <Col md={2} className="d-none d-md-block">{navCol}</Col> */}
          <Col className="d-print-none">{children}</Col>
        </Row>
        <Row>
          <Col xs={12} className="txt-c mt-4 mb-2">
            <p><a href="/regulamin-2024-11-20.pdf" target='_blank' rel="noindex, nofollow" className="text-primary">Regulaminu</a> 
              <span className="mx-2">|</span> 
               <a href="/polityka-prywatnosci.pdf" target='_blank' rel="noindex, nofollow" className="text-primary">Polityki Prywatności</a></p>
          </Col>
        </Row>
      </Container>
    );
  }
  
  export default AppContainer;