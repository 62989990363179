import { Helmet } from 'react-helmet-async';
import { Link, useLoaderData, useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { Dispatch, MouseEventHandler, SetStateAction, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RowExamNew } from "../Type/RowExamNew";
import { Button, Card, CardBody, Col, ListGroup, ListGroupItem, Nav, Pagination, Placeholder, Row } from "react-bootstrap";
import axios from "axios";
import { RowExamIndex } from "../Type/RowExamIndex";
import { RowUserExamQuestion } from "../Type/RowUserExamQuestion";
import { RowUserExamQuestionOption } from "../Type/RowUserExamQuestionOption";
import { RecordQuestionDescription, RecordQuestionOptionDescription } from "../Type/RecordQuestionDescription";
import { MdArrowLeft, MdArrowRight, MdCheck, MdDangerous, MdQuestionMark } from "react-icons/md";
import { QuestionOptionDescriptionDisplay } from "../Componets/QuestionOption";
import QuestionLoadingPlaceholder, { QuestionNoAccessPlaceholder } from '../Componets/QuestionLoadingPlaceholder';
import { FaAngleLeft, FaAngleRight, FaCheck, FaXmark } from 'react-icons/fa6';
import { stateQuestion } from '../Componets/Question/State';
import { QuestionCardContent } from '../Question/Component/Content';

function MyExamResultPreviewPagePaginator({examID,current}:{examID:number,current:number}){
    const [examIndexData,setExamIndexData]          = useState<Array<RowExamIndex>|null>(null);
    let navigate = useNavigate();
    
    if (examIndexData===null){
        axios.get(`/egzamin/get-exam-index/${examID}`).then((response) => {
            setExamIndexData(response.data.data);
        }, (error) => {
            // console.log(error);
        });
        return null;
    }

    // let pages = examIndexData;
    // let firstQuestion = examIndexData[0];
    // let lastQuestion = examIndexData[examIndexData.length-1];
    // if (examIndexData.length>10){
    //     let firstPageTry = current-4;
    //     let lastPageTry  = current+4;
    //     let addToLast    = 0;
    //     let addToFirst   = 0;

    //     if (firstPageTry<1){
    //         addToLast   = -firstPageTry;
    //         firstPageTry=1;
    //         lastPageTry+=addToLast;
    //     }

    //     pages = examIndexData.filter(i=>i.Order>=firstPageTry && i.Order<=lastPageTry);
    // }

    return <>
        {/* <Pagination className="text-center justify-content-center"> */}
            {/* <Pagination.First title="Skok do pierwszego pytania" onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${firstQuestion.Order}`)} /> */}
            {examIndexData.map(function(item,idx){
                let type = item.Answer===item.CorrectAnswer?'outline-success':'outline-danger';
                if (current===item.Order)
                    type = item.Answer===item.CorrectAnswer?'success':'danger';
                return <Button size="sm" className="m-1" variant={type} onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${item.Order}`)} key={`page-${idx}`}>
                    {item.Order} {item.Answer===item.CorrectAnswer?<FaCheck />:<FaXmark />}
                </Button>;
            })}
            {/* <Pagination.Last title="Skok do ostatniego pytania" onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${lastQuestion.Order}`)} /> */}
        {/* </Pagination> */}
    </>
}

function MyExamResultPreviewPageIndexView({examID}:{examID:number}){
    const [examIndexData,setExamIndexData]          = useState<Array<RowExamIndex>|null>(null);
    let navigate = useNavigate();
    
    if (examIndexData===null){
        axios.get(`/egzamin/get-exam-index/${examID}`).then((response) => {
            setExamIndexData(response.data.data);
        }, (error) => {
            // console.log(error);
        });
    }
        
    return <>
        <h2 className="my-3 text-primary">Index <span className="d-none d-lg-inline">pytań</span></h2>
        {examIndexData === null ?
            <Spinner key="question-index-loader" animation="border" role="status" className='my-5'><span className="visually-hidden">Loading...</span></Spinner>
            :
            <ListGroup as="ul" variant="flush" key="question-index-list-group">
                {examIndexData.map(function(item,idx){
                    return <ListGroupItem key={item.ID} variant={item.CorrectAnswer===item.Answer?'success':'danger'} action onClick={() => navigate(`/app/egzamin-przegladaj/${examID}/${item.Order}`)}>
                            <Button variant={item.CorrectAnswer===item.Answer?'success':'danger'} className="text-white">{item.Order}</Button> 
                            {item.Answer !== null ? 
                                <span className="text-white badge text-bg-primary ms-2 d-none d-md-inline">{item.Answer}</span> 
                                : 
                                <span className="badge text-bg-info ms-2 text-dark d-none d-md-inline">Brak odpowiedzi</span> 
                            }
                         </ListGroupItem>;
                })}
            </ListGroup>
        }
    </>
}

function MyExamResultPreviewPageQuestionOption({rowQuestion,rowOption,currentAnswer,showDescription,optionDescription}:
    {rowOption:RowUserExamQuestionOption,rowQuestion:RowUserExamQuestion,currentAnswer:string|null,showDescription:boolean,optionDescription:RecordQuestionOptionDescription|null|undefined})
{
    return <ListGroup.Item variant={currentAnswer===rowOption.Letter?
                (currentAnswer===rowQuestion.CorrectAnswer?'success':'danger')
                :
                (rowOption.Letter===rowQuestion.CorrectAnswer?'success':'default')} className="fs-5" key={rowOption.ID}>
            <span className="text-primary fw-bold">{rowOption.Letter}.</span> <span dangerouslySetInnerHTML={{__html:rowOption.Content}}></span>
            {currentAnswer===rowOption.Letter?(currentAnswer===rowQuestion.CorrectAnswer?<MdCheck />:<MdDangerous />):null}
            <QuestionOptionDescriptionDisplay showDescription={showDescription} optionDescription={optionDescription} />
           </ListGroup.Item>
}

function MyExamResultPreviewPageQuestionOptionsList({rowQuestion,rowOptionsAbout,showDescription}:
    {rowQuestion:RowUserExamQuestion,rowOptionsAbout:undefined|null|RecordQuestionOptionDescription[],showDescription:boolean}) 
{
    return <>
        <ListGroup variant="flush">
            {rowQuestion.Options?.map(function(item,idx){
                return <MyExamResultPreviewPageQuestionOption 
                        rowQuestion={rowQuestion} 
                        rowOption={item} 
                        key={item.ID} 
                        currentAnswer={rowQuestion.Answer}
                        optionDescription={rowOptionsAbout?.find(o=>o.Letter===item.Letter)}
                        showDescription={showDescription}
                    />
            })}
        </ListGroup>
    </>
}

function MyExamResultPreviewPageQuestionNav({rowQuestion,onClickShowMoreAboutQuestion,examID,data}:
        {rowQuestion:RowUserExamQuestion,examID:number,onClickShowMoreAboutQuestion:MouseEventHandler<HTMLElement>,data:stateQuestion}){    
    let prevQuestionLink = null;
    let nextQuestionLink = null;
    let isMore = false;
    if (rowQuestion.PreviousOrder!==null)
        prevQuestionLink = <Nav.Item><Nav.Link as={Link} to={`/app/egzamin-przegladaj/${examID}/${rowQuestion.PreviousOrder}`}><FaAngleLeft /><span className="d-none d-md-inline"> Poprzednie pytanie</span></Nav.Link></Nav.Item>
    else
        prevQuestionLink = <Nav.Item><Nav.Link disabled><FaAngleLeft /><span className="d-none d-md-inline"> Poprzednie pytanie</span></Nav.Link></Nav.Item>

    if (rowQuestion.NextOrder!==null)
        nextQuestionLink = <Nav.Item><Nav.Link as={Link} to={`/app/egzamin-przegladaj/${examID}/${rowQuestion.NextOrder}`}><span className="d-none d-md-inline">Kolejne pytanie</span> <FaAngleRight className="fw-bold" /></Nav.Link></Nav.Item>
    else
        nextQuestionLink = <Nav.Item><Nav.Link disabled><span className="d-none d-md-inline">Kolejne pytanie</span> <FaAngleRight className="fw-bold" /></Nav.Link></Nav.Item>

    
    if (rowQuestion.IsMore===true){
        isMore=true;
    }else{
        if (rowQuestion.Options?.some(a => a.IsMore === true))
            isMore=true;
    }
        
    return <>
        <Nav fill variant="underline" className="mt-3">
            {prevQuestionLink}
            {isMore === true ? 
            <Nav.Item><Nav.Link className="position-relative" title="Pokaź omówienie do tego pytania" onClick={onClickShowMoreAboutQuestion}><MdQuestionMark /> Pokaż omówienie
                {/* {isQuestionMoreLoading===true ? <Spinner animation="border" role="status" size="sm" className='mx-5'><span className="visually-hidden">Loading...</span></Spinner> : null } */}
            </Nav.Link></Nav.Item> : null }
            {nextQuestionLink}
        </Nav>
    </>
}

function MyExamResultPreviewPageQuestion({examID,questionID}:{examID:number,questionID:number}){
    const [data,setData] = useState<stateQuestion>({rowQuestion:null,rowQuestionAbout:null,showQuestionAbout:false,lockQuestion:false,accessMessage:null});

    // console.log(data.rowQuestion );
    let rowQuestion             = data.rowQuestion as RowUserExamQuestion;
    let dataQuestionAbout       = data.rowQuestionAbout;
    let showQuestionAbout       = data.showQuestionAbout;
    let loadingQuestionAbout    = useRef<boolean>(false);
    let loadingQuestion         = useRef<boolean>(false);

    if (rowQuestion !== null){
        if (questionID !==rowQuestion.Order ){
            setData({rowQuestion:null,rowQuestionAbout:null,showQuestionAbout:false,lockQuestion:false,accessMessage:null});
            return <></>
        }
    }

    if (dataQuestionAbout===null && showQuestionAbout===true && loadingQuestionAbout.current===false){
        loadingQuestionAbout.current=true;
        axios.get(`/pytanie/get-description/${rowQuestion?.QuestionID}`).then((response) => {
            loadingQuestionAbout.current=false;
            setData({...data,rowQuestionAbout:response.data.data});
          }, (error) => {
            loadingQuestionAbout.current=false;
        });
    }

    if (loadingQuestion.current===true) return <QuestionLoadingPlaceholder />

    if (rowQuestion===null&&loadingQuestion.current===false&&data.accessMessage===null){
        loadingQuestion.current=true;
        axios.get(`/egzamin/get-user-exam-question/${examID}/question/${questionID}`).then((response) => {
            loadingQuestion.current=false;
            if (response.data.access){
                setData({...data,accessMessage:response.data.access,rowQuestion:response.data.data});
            }else{
                setData({...data,rowQuestion:response.data.data,accessMessage:null});
            }
          }, (error) => {
            loadingQuestion.current=false;
        });

        return <QuestionLoadingPlaceholder />
    }else{
        // console.log(rowQuestion);
    }

    if (data.accessMessage!==null){
        return <QuestionNoAccessPlaceholder message={data.accessMessage} rowQuestion={rowQuestion} />
    }

    if (rowQuestion===null)return <QuestionLoadingPlaceholder />

    let rowQuestionPreview = {ID:rowQuestion.ID, Type:null, Content:rowQuestion.Content, CorrectAnswer:rowQuestion.CorrectAnswer,
        IsMore:rowQuestion.IsMore, ExamYear:rowQuestion.ExamYear, ExamSeason:rowQuestion.ExamSeason, 
        ExamSeasonName:rowQuestion.ExamSeasonName, ExamOrder:rowQuestion.ExamOrder, LibraryName:rowQuestion.LibraryName,
        LibraryID:rowQuestion.LibraryID, FavoriteID:rowQuestion.FavoriteID,
        SpecID:rowQuestion.SpecID, SpecName:rowQuestion.SpecName, Tags:data.rowQuestionAbout?.Tags!, 
        Source:data.rowQuestionAbout?.Source!, Options:rowQuestion.Options, CommentsCount: rowQuestion.CommentsCount
};


    return <>
        <Card key={`question-card-${questionID}`} className="mb-4">
            <CardBody className="lh-base">
                <QuestionCardContent rowQuestion={rowQuestionPreview} rowAbout={dataQuestionAbout} showAbout={showQuestionAbout} />
                <MyExamResultPreviewPageQuestionOptionsList 
                    rowQuestion={rowQuestion} 
                    rowOptionsAbout={dataQuestionAbout?.Options} 
                    showDescription={showQuestionAbout} />
            </CardBody>
            <MyExamResultPreviewPageQuestionNav 
                    rowQuestion={rowQuestion} 
                    data={data}
                    examID={examID}
                    onClickShowMoreAboutQuestion={() => setData({...data,showQuestionAbout:true})}
                />
        </Card>
    </>


    // return <><h1>Muszę tutaj naprawić....</h1></>

    // if ((dataQuestionAbout===null && showQuestionAbout===true && loadingQuestionAbout.current===false))
    // {
    //     loadingQuestionAbout.current=true;
    //     axios.get(`/egzamin/get-question-description/${rowQuestion?.QuestionID}`).then((response) => {
    //         loadingQuestionAbout.current=false;
    //         setDataQuestionAbout(response.data.data);
    //     }, (error) => {
    //         loadingQuestionAbout.current=false;
    //     });
    // }

    // if (rowQuestion===null || rowQuestion?.ID!==questionID){
    //     axios.get(`/egzamin/get-user-exam-question/${examID}/question/${questionID}`).then((response) => {
    //         onClickQuestionID(response.data.data.Order);
    //         setRowQuestion(response.data.data);
    //         setDataQuestionAbout(null);
    //         setShowQuestionAbout(false);
    //       }, (error) => {
    //     });

    //     return <QuestionLoadingPlaceholder />
    // }else{
    //     // console.log(rowQuestion);
    // }

    // return <>
    //     <Card key={questionID}>
    //         <CardBody className="lh-base">
    //             <CardTitle>Pytanie {questionID}</CardTitle>
    //             {/* <CardText> */}
    //                 <div className="fs-4 mb-2" style={{whiteSpace: "pre-wrap"}}  dangerouslySetInnerHTML={{__html:rowQuestion.Content}}></div>
    //                 {showQuestionAbout===true && dataQuestionAbout !== null ? 
    //                     <Alert variant="info">
    //                         <div dangerouslySetInnerHTML={{__html:dataQuestionAbout.Content}}></div>
    //                         {dataQuestionAbout.Source?.length>0?<>
    //                         <h3 className="h5 text-primary mb-2">Źródła</h3>
    //                         <ListGroup className="mb-4">
    //                             {dataQuestionAbout.Source.map(function(item,idx){
    //                                 return <ListGroup.Item key={idx}>{item.Content} {item.Addon!==undefined&&item.Addon?.length!==undefined?(item.Addon?.length>0?item.Addon:null):null}</ListGroup.Item>
    //                             })}
    //                         </ListGroup>
    //                         </>
    //                         :null}
    //                         {dataQuestionAbout.Tags?.length>0?<>
    //                             <h3 className="h5 text-primary mb-2">Tagi</h3>
    //                             <div className="mb-4">
    //                                 {dataQuestionAbout.Tags.map(function(i,idx){
    //                                     return <Badge pill bg="primary">{i.Name}</Badge>
    //                                 })}
    //                             </div>
    //                         </>:null}
    //                     </Alert>
    //                     :
    //                     null
    //                 }
    //                 <MyExamResultPreviewPageQuestionOptionsList 
    //                     rowQuestion={rowQuestion} 
    //                     rowOptionsAbout={dataQuestionAbout?.Options} 
    //                     showDescription={showQuestionAbout} />
    //                 {/* <QuestionAnswerList rowQuestion={rowQuestion} rowExam={exam} rowQuestionAbout={dataQuestionAbout} showDescription={showQuestionAbout} /> */}
    //             {/* </CardText> */}
    //             <MyExamResultPreviewPageQuestionNav 
    //                 rowQuestion={rowQuestion} 
    //                 isQuestionMoreLoading={loadingQuestionAbout?true:false}
    //                 onClick={onClickQuestionID}
    //                 onClickShowMoreAboutQuestion={() => setShowQuestionAbout(true)}
    //             />
    //         </CardBody>
    //     </Card>
    // </>
}


function MyExamResultPreviewPagePlaceholder(){
    return <>
        <Row>
            <Col lg="2" md="2" xs="4"></Col>
            <Col lg="10" md="10" xs="8">
                <h1 className="my-3 text-primary"><Placeholder xs={10} animation='glow' /></h1>
                <QuestionLoadingPlaceholder />
            </Col>
        </Row>
    </>
}

export default function MyExamResultPreviewPage(){
    let [ examData, setExamData ]          = useState<RowExamNew|null>(null);
    let { examID, questionID }             = useParams();
    let isLoading                          = useRef<boolean>(false);

    if (!questionID) questionID = '1';
    
    if (examData===null&&isLoading.current===false){
        isLoading.current   = true;
        axios.get(`/egzamin/get-user-exam/${examID}`).then((response) => {
            setExamData(response.data.data);
            isLoading.current=false;
          }, (error) => {
            isLoading.current=false;
        });

        return <MyExamResultPreviewPagePlaceholder />
    }

    if (examData===null) return <MyExamResultPreviewPagePlaceholder />

    return <>
    <Helmet>
        <title>{examData.Name} - przegląd pytań i odpowiedzi - pytanie {questionID}</title>
    </Helmet>
    <Row>
        {/* <Col lg="2" md="2" xs="2"><MyExamResultPreviewPageIndexView examID={examData.ID} /></Col> */}
        <Col xs={12}>
            <h1 className="my-3 text-primary">{examData.Name} - przegląd</h1>
            <MyExamResultPreviewPageQuestion examID={examData.ID} questionID={parseInt(questionID as string)} />
            <MyExamResultPreviewPagePaginator examID={examData.ID} current={parseInt(questionID)} />
        </Col>
    </Row>
    
    {/* <Question questionID={questionID} exam={examData} /> */}
    </>;
}