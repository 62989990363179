import { useRef, useState } from "react";
import checkResponse from "../Helpers/CheckResponse";
import { RowSpec } from "../Type/RowSpec";
import { RowExam } from "../Type/RowExam";
import axios from "axios";
import DrawSpinner from "../Componets/DrawSpinner";
import { Helmet } from "react-helmet-async";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import ExamCardFooter from "./Component/ExamCardFooter";
import { FaCircleInfo } from "react-icons/fa6";
import OpenExamCreateModal from "../Componets/OpenExamCreateModal";
import ModalExamInfo from "../Componets/ModalExamInfo";


interface stateCreatePageCEM{
    specList:RowSpec[]|null,
    specID:number|null,
    examList:RowExam[]|null,
    rowExam:RowExam|null
}

function ExamPageCEM(){
    const [state,setState]                          = useState<stateCreatePageCEM>({specList:null,specID:null,examList:null,rowExam:null});
    const [examInfoModal,setExamInfoModal]          = useState<RowExam|null>(null);
    const [showExamOpenModal,setShowExamOpenModal]  = useState<boolean>(false);
    const isLoading                                 = useRef<boolean>(false);
    const isExamListLoading                         = useRef<boolean>(false);

    if (state.specList===null){
        if (isLoading.current===false){
            isLoading.current=true;
            axios({url:'/specjalizacja/list',maxRedirects:0}).then((r)=>{
                isLoading.current=false;
                setState({specList:r.data.data,specID:null,examList:null,rowExam:null});
            }).catch((e)=>{
                if (e.response){
                    if (checkResponse(e.response.status))
                        return;
                }
            });
        }

        return <DrawSpinner text="Pobieranie listy specjalizacji..." className="me-2" />
    }

    // console.log(state);
    if (state.specID!==null&&state.examList===null&&isExamListLoading.current===false){
        isExamListLoading.current=true;
        axios({url:`/egzamin/list/${state.specID}`,maxRedirects:0}).then((r)=>{
            isExamListLoading.current=false;
            setState({...state,examList:r.data.data});
        }).catch((e)=>{
            isExamListLoading.current=false;
        });
    }

    function specSelectChange(e:any) {
        e.preventDefault();
        setState({...state,specID:e.target.value,examList:null});
    }

    function openExamClick(e:RowExam){
        // e.preventDefault();
        setShowExamOpenModal(true);
        setState({...state,rowExam:e});
    }

    function closeExamClick(){
        // e.preventDefault();
        setShowExamOpenModal(false);
    }

    return <>
        <Helmet>
            <title>Baza egzaminów CEM - ZdajLEKko</title>
        </Helmet>
        <Row>
            <Col xs={12}>
                <Form.Group as={Row} className="mb-3" controlId="formSpec">
                    <Form.Label column sm={2}>
                        Specjalizacja
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Select aria-label="Wybierz specjalizację..." onChange={specSelectChange}>
                            <option value="0">Wybierz specjalizację...</option>
                            {
                            state.specList?.map(function(d, idx){
                                return (<option value={d.ID} key={d.ID}>{d.Name} ({d.QuestionCount})</option>)
                            })
                            }
                        </Form.Select>
                    </Col>
                </Form.Group>
            </Col>
            {isExamListLoading.current===true && state.examList===null ?
                <Col xs={12}>
                    <DrawSpinner className="me-2" text="Pobieranie listy egzaminów dla wybranej specjalizacji..." />
                </Col>
                : (isExamListLoading.current===false&&state.examList!==null)?
                    state.examList.length===0&&state.specID===0?
                        <Col xs={12}><Alert variant="info">Proszę wybierz najpierw specjalizację z listy, aby załadować listę egzaminów.</Alert></Col>
                        :
                    state.examList.map(function(i,idx){
                        return <div className="col-12 col-md-4 col-lg-3 mb-3" key={i.ID}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h2 className="card-title">{i.SessionYear} <small className="text-secondary">{i.SessionSeasonName}</small></h2>
                                            <p>Liczba pytań : {i.QuestionCount}, podzielone na {i.Categories.length} kategorie.
                                            
                                            </p>
                                            <Button variant="primary" className="text-white" size="sm" onClick={() => openExamClick(i)}>Rozpocznij</Button>
                                            <Button variant="info" title="Zobacz z jakich kategorii są pytania" size="sm" className="ms-1 text-white" onClick={() => setExamInfoModal(i)}><FaCircleInfo /></Button>
                                        </div>
                                        <ExamCardFooter score={i.UserLastScore} time={i.UserEndTime} />
                                    </div>
                                </div>
                    })
                    :
                    null
            }
        </Row>
        <OpenExamCreateModal show={showExamOpenModal} onClose={closeExamClick} rowExam={state.rowExam} />
        <ModalExamInfo exam={examInfoModal} onClose={setExamInfoModal} />
    </>
}

export default ExamPageCEM;