import { Dispatch, SetStateAction, useRef, useState } from "react";
import { RecordQuestionLibrary } from "../Type/RecordQuestionsLibrary";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import DrawSpinner from "../Componets/DrawSpinner";
import ExamCardFooter from "./Component/ExamCardFooter";


interface iCourseState{
    ID:number|null;
    isLoading:boolean;
}


function LibraryItem({item,courseState,setCourseState}:
    {item:RecordQuestionLibrary,courseState:iCourseState,setCourseState:Dispatch<SetStateAction<iCourseState>>})
{
return <>
<Col xs={12} md={3} lg={4} className=" flex-fill">
    <Card className="mb-2">
        <Card.Body>
            <Card.Title className="h4">{item.Name} - <span className="h6">{item.Count} pytań</span></Card.Title>
            <Card.Subtitle className="mb-2 text-muted fs-6">{item.Category}</Card.Subtitle>
            <div className="card-text" dangerouslySetInnerHTML={{__html:item.Description}}></div>
            {courseState.isLoading===false?
                <Button variant="primary text-white" onClick={() => setCourseState({ID:item.ID,isLoading:true})}>Rozpocznij</Button>
                :
                <Button disabled variant="primary text-white">
                    {courseState.ID===item.ID?
                        <><DrawSpinner text="Uruchamianie" size="sm" /></>
                        :<>Rozpocznij</>}
                </Button>
            }
        </Card.Body>
        <ExamCardFooter score={item.UserLastScore} time={item.UserEndTime} />
    </Card>
</Col>
</>
}

function ExamPageLibrary(){
    let isLoading = useRef<boolean>(false);
    let isCourseLoading = useRef<boolean>(false);
    let [ libData, setLibData ] = useState<RecordQuestionLibrary[]|null>(null);
    let [ course, setCourse] = useState<iCourseState>({ID:null,isLoading:false});
    let [filter,setFilter]              = useState<string>('');
    let navigate = useNavigate();

    if (course.ID&&isCourseLoading.current===false){
        isCourseLoading.current=true;
        axios.post(`/pytania-autorskie/create`,{
            LibraryID:course.ID
        }).then((response) => {
            isCourseLoading.current=false;
            // console.log(response.data);
            navigate(`/app/egzamin/${response.data.data.ID}/1`);
          }, (error) => {
            isCourseLoading.current=false;
            setCourse({ID:null,isLoading:false});
        });
    }

    if (libData===null && isLoading.current===false){
        isLoading.current=true;
        axios.get(`/pytania-autorskie`).then((response) => {
            isLoading.current=false;
            setLibData(response.data.data);
          }, (error) => {
            isLoading.current=false;
        });
    }

    let filteredList = libData;
    if (filter.length>=1&&libData!==null){
        filteredList  = libData.filter(x=>x.Name.toLowerCase().includes(filter.toLowerCase()));
    }


    return <>
        <Helmet>
            <title>Lista katalogów z pytaniami autorskimi</title>
        </Helmet>
        <Row>
            {filteredList===null?
                <Col><DrawSpinner text="Pobieranie listy katalogów pytań autorskich..." className="me-2" /></Col>
                :
                <Col xs={12}>
                    <FloatingLabel controlId="itemsFilter" label="Filtruj... Czego szukasz?" className="mb-3">
                        <Form.Control type="text" placeholder="np. PES" onChange={(e)=>setFilter(e.target.value!)} autoComplete="false" />
                    </FloatingLabel>
                    <div className="d-flex row">
                {filteredList.map(function(i,idx){
                    return <LibraryItem key={`library-${idx}`} item={i} courseState={course} setCourseState={setCourse} />
                })}
                </div></Col>
            }
        </Row>
    </>
}

export default ExamPageLibrary;