import { Card, CardBody, CardTitle, ListGroup, Placeholder } from "react-bootstrap";
import { RowUserExamQuestion } from "../Type/RowUserExamQuestion";
import { QuestionTitle } from "../Question/Component/Title";

function QuestionLoadingPlaceholder(){
    return <>
    <Card>
        <CardBody className="lh-base">
            <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                <Placeholder xs={6} /> <Placeholder xs={8} />
            </Placeholder>
            <Placeholder as={ListGroup} variant="flush" animation="glow">
                <ListGroup.Item className="fs-5"><span className="text-primary fw-bold">A.</span> <Placeholder xs={7} /></ListGroup.Item>
                <ListGroup.Item className="fs-5"><span className="text-primary fw-bold">B.</span> <Placeholder xs={5} /></ListGroup.Item>
                <ListGroup.Item className="fs-5"><span className="text-primary fw-bold">C.</span> <Placeholder xs={9}  /></ListGroup.Item>
                <ListGroup.Item className="fs-5"><span className="text-primary fw-bold">D.</span> <Placeholder xs={4} /></ListGroup.Item>
                <ListGroup.Item className="fs-5"><span className="text-primary fw-bold">E.</span> <Placeholder xs={6} /></ListGroup.Item>
            </Placeholder>
        </CardBody>
    </Card>
</>
}

export function QuestionNoAccessPlaceholder({message,rowQuestion}:{message:string,rowQuestion:RowUserExamQuestion|null}){
    return <>
    <Card className="mb-2">
        <CardBody className="lh-base">
            <CardTitle className="d-flex flex-row position-relative">                
                <QuestionTitle question={rowQuestion!} />
            </CardTitle>
            <div className="fs-4 mb-2" style={{whiteSpace: "pre-wrap"}}>{message}</div>
        </CardBody>
    </Card>
</>
}

export default QuestionLoadingPlaceholder;