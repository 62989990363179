import { Alert, Button, Form, InputGroup, Table } from "react-bootstrap";
import DrawSpinner from "../Componets/DrawSpinner";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useContext, useRef, useState } from "react";
import GlobalContext from "../Componets/GlobalContext";
import { useNavigate } from "react-router-dom";


interface recordQuestionNotSeenListItem {
    ID:number;    
    SpecID:number|null;
    SpecName:string|null;
    Order:number|null;
    SessionYear:number|null;
    SessionSeason:number|null;
    SessionSeasonName:string|null;
    CategoryID:number|null;
    CategoryName:string|null;
    LibraryID:number|null;
    LibraryName:number|null;
    ContentShort:string|null;
};

interface stateExamCreateNotSeen{
    qList:recordQuestionNotSeenListItem[]|null;
    favSelected:number[];
    favCreate:boolean;
    isMore:boolean;
    loadMore:boolean;
}

function ExamPageNotSeen() {
    const {globalState,globalDispatch} = useContext(GlobalContext);
    let navigate                    = useNavigate();
    let [state,setState]            = useState<stateExamCreateNotSeen>({loadMore:false,isMore:false,qList:null,favSelected:[],favCreate:false});
    let isCreating                  = useRef<boolean>(false);
    let inputName                   = useRef<HTMLInputElement|null>(null);
    let inputType                   = useRef<HTMLSelectElement|null>(null);
// console.log(state);
    if (state.qList===null){
        axios.post('/pytanie/get-not-seen',{id:null}).then((r)=>{
            let newState = {...state,
                isMore : r.data.isMore,
                qList : r.data.data,
                reloadFavList:false
            };
            setState(newState);
        }).catch((e)=>{

        });
        return <><DrawSpinner text="Pobieranie pytań, których nigdy nie widziałeś..." /></>
    }

    if (state.isMore && state.loadMore){
        const last = state.qList[state.qList.length-1];
        if (last){
            axios.post('/pytanie/get-not-seen',{id:last.ID}).then((r)=>{
                const newList = state.qList?.concat(r.data.data);
                setState({...state,
                        favCreate:false,
                        isMore:r.data.isMore,
                        loadMore:false,
                        qList:newList!});
            }).catch((e)=>{

            });
        }else{
            setState({...state,isMore:false,loadMore:false});
        }
    }

    if (state.favCreate===true&&isCreating.current===false){
        isCreating.current=true;
        let selectedType = inputType.current?inputType.current.options[inputType.current.selectedIndex].value:'nauka';
        axios.post('/egzamin/not-seen',{
                ids:state.favSelected,
                name:inputName.current?inputName.current.value:null,
                type:selectedType
        }).then((r)=>{
            navigate(`/app/egzamin/${r.data.data.ID}/1`);
        }).catch((e)=>{
            isCreating.current=false;
            setState({...state,favCreate:false});
        });
    }

    // console.log(globalState);

    return <>
        <Helmet>
            <title>Pytania, których nie widziałeś</title>
        </Helmet>
        {state.qList.length > 0 ?
        <>
            <p>Lista pytań, które nigdy nie zostały wykorzystane do utworzenia egzaminu. Czyli takie, których nigdy nie widziałeś w aplikacji w formie pytania.</p>
            <Table striped bordered hover responsive size="sm">
                <thead>
                    <tr>
                        <td className="text-center">
                            <Form.Check type="checkbox"
                                checked={state.favSelected.length===state.qList.length} 
                                onChange={()=>{
                                    state.favSelected.length===state.qList?.length?
                                        setState({...state,favSelected:[]})
                                        :
                                        setState({...state,favSelected:state.qList?state.qList.map(x=>x.ID):[]})}
                                    }
                            />
                        </td>
                        <td>Sesja / Autorskie</td>
                        <td>Kategoria</td>
                        <td>Skrót</td>
                    </tr>
                </thead>
                <tbody>
                    {state.qList.map(function(i,idx){
                        let isChecked = state.favSelected.some(f=>f===i.ID);
                        return <tr key={`fav-item-${idx}`}>
                            <td className="text-center">
                                <Form.Check type="checkbox" checked={isChecked}
                                    onChange={() => {isChecked?
                                                setState({...state,favSelected:state.favSelected.filter(f=>f!==i.ID)})
                                                :
                                                setState({...state,favSelected:[...state.favSelected,i.ID]})
                                    }}
                                    />
                            </td>
                            <td>{
                                i.SessionYear ? <>{`${i.SessionYear}\\${i.SessionSeasonName}\\${i.Order}`}</> 
                                              : <>{`Autorskie : ${i.LibraryName}`}</> 
                            }</td>
                            <td>{i.CategoryName?i.CategoryName:<>-</>}</td>
                            <td>{i.ContentShort}...</td>
                        </tr>
                    })}
                </tbody>
                <caption>{state.qList.length} pytań w niewidzianych. 
                    <Button disabled={!state.isMore || state.loadMore} variant="primary" size="sm" className="ms-2" onClick={()=>setState({...state,loadMore:true})}>
                        <DrawSpinner show={state.loadMore} size="sm" />
                        Pobierz więcej pytań
                    </Button>
                </caption>
            </Table>
            
            
            <InputGroup className="mb-5">
                <Form.Select ref={inputType} disabled={state.favCreate} aria-label="Wybierz tryb">
                    <option value="nauka">Tryb nauki</option>
                    <option value="egzamin">Tryb egzaminu</option>
                </Form.Select>
                <Form.Control maxLength={40} ref={inputName} disabled={state.favCreate} type="text" placeholder="Nazwa..." aria-label="Wprowadź nazwę dla egzaminu..." />
                <Button disabled={state.favCreate || state.favSelected.length===0} onClick={()=>setState({...state,favCreate:true})}>
                    <DrawSpinner show={state.favCreate} size="sm" />
                    Rozpocznij
                </Button>
            </InputGroup>
            </>
        :
        <Alert variant="info">Jeśli widzisz ten komunikat to znaczy, że przynajmniej raz widziałeś każde pytanie, które mamy w bazie danych. Szacuneczek! Tyle klikania :D </Alert> }
        </>
}

export default ExamPageNotSeen;