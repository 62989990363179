import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import axios from "axios";
import QuestionLoadingPlaceholder from "../Componets/QuestionLoadingPlaceholder";
import { RecordQuestionPreview } from "./Interface/QuestionPreview";
import { Helmet } from "react-helmet-async";
import { Alert, Card, CardBody, ListGroup, Nav } from "react-bootstrap";
import ExamQuestionComment from "./Comment";
import { QuestionCardContent } from "./Component/Content";
import { RecordQuestionDescription } from "../Type/RecordQuestionDescription";
import { MdArrowBack, MdDangerous, MdQuestionMark } from "react-icons/md";
import { QuestionOptionDescriptionDisplay } from "../Componets/QuestionOption";
import DrawSpinner from "../Componets/DrawSpinner";

interface QuestionPreviewState{
    question:RecordQuestionPreview|null;
    about:RecordQuestionDescription|null;
    showQuestionAbout:boolean;
    showAnswer:boolean;
};

interface QuestionPreviewNavParams {
    isMore:boolean;
    onClickShowMoreAboutQuestion:(mode:boolean)=>void;
    showDescription:boolean;
    isLoadingDescription:boolean;
    setShowComments:Dispatch<SetStateAction<boolean>>;
    showAnswer:boolean;
    commentsCount:number|null;
    onClickShowCorrect:(mode:boolean)=>void;
}

function QuestionPreviewNav({isMore,onClickShowMoreAboutQuestion,showDescription,isLoadingDescription,setShowComments,showAnswer,onClickShowCorrect,commentsCount}:QuestionPreviewNavParams){
    let navigate = useNavigate();
    let {state}  = useLocation();

    let backItem = <></>
    // console.log(state);
    if (state){
        if ("back" in state!){
            backItem = <Nav.Item><Nav.Link onClick={()=>navigate(state.back)}><MdArrowBack /> powrót</Nav.Link></Nav.Item>
        }
    }

    return <>
        <Nav fill variant="underline" className="mt-3">
            {backItem}
            <Nav.Item key="showcorrect">
                <Nav.Link active={showAnswer} onClick={()=>onClickShowCorrect(!showAnswer)}>
                    {!showAnswer?'Pokaż odpowiedź':'Ukryj odpowiedź'}
                </Nav.Link>
            </Nav.Item>
            {isMore === true ? 
            <Nav.Item key="navmore">
                <Nav.Link className="position-relative" disabled={isLoadingDescription} active={showDescription} title="Pokaź omówienie do tego pytania" 
                        onClick={()=>onClickShowMoreAboutQuestion(!showDescription)}>
                            <DrawSpinner show={isLoadingDescription} />
                            <MdQuestionMark /> <span className="d-none d-md-inline">Pokaż omówienie</span><span className="d-sm-inline d-md-none">Omówienie</span>
                </Nav.Link>
            </Nav.Item> : null }
            <Nav.Item key="navComments">
                <Nav.Link onClick={()=>setShowComments(true)}>
                    <span className="position-relative">Dyskusja&nbsp;&nbsp;
                    {commentsCount! > 0 ? <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{commentsCount}</span> : null}
                    </span>
                </Nav.Link>
            </Nav.Item>
        </Nav>
    </>
}

function QuestionPreview({showNowComments,QuestionID,showProposalBtn,showProposal}:{showNowComments:boolean,QuestionID:number|null,showProposalBtn?:boolean,showProposal?:boolean}){
    let { questionID }            = useParams();
    let [ state, setState ]       = useState<QuestionPreviewState>({question:null,about:null,showQuestionAbout:false,showAnswer:false});
    let isLoadingAbout            = useRef<boolean>(false);
    let question                  = state.question;
    let about                     = state.about;
    let showQuestionAbout         = state.showQuestionAbout;
    let [showComments,setShowComments]  = useState<boolean>(showNowComments);

    if (questionID===undefined||questionID===null||questionID.length<=0){
        questionID=QuestionID+'';
    }

    if (question===null){
        axios.post('/pytanie/get',{id:questionID}).then((r) => {
            setState({...state,question:r.data.data});
          }, (error) => {
        });
        return <QuestionLoadingPlaceholder />
    }

    if (about===null && showQuestionAbout===true&&isLoadingAbout.current===false){
        isLoadingAbout.current=true;
        axios.get(`/pytanie/get-description/${questionID}`).then((r) => {
            isLoadingAbout.current=false;
            setState({...state,about:r.data.data});
          }, (error) => {
            isLoadingAbout.current=false;
        });
    }
    // console.log(question);

    return <>
    <Helmet>
        <title>Podgląd pytania</title>
    </Helmet>
    <Card key={`question-card-${questionID}`} className="mb-4">
        <CardBody className="lh-base">
                <QuestionCardContent rowQuestion={question} rowAbout={about} showAbout={state.showQuestionAbout} disableProposalBtn={(showProposalBtn??false)===true?false:true} />
                {question.CorrectAnswer==='X'?<Alert variant="danger" className="my-4"><MdDangerous size={64} /> <span className="fs-5">Pytanie niezgodne z aktualną wiedzą medyczną. Nie ma prawidłowej odpowiedzi.</span></Alert>:null}
                <ListGroup variant="flush">
                {question?.Options?.map(function(item,idx){
                    let optionDesc = about?.Options.find(x=>x.Letter===item.Letter);
                    return <ListGroup.Item variant={state.showAnswer?(item.Letter===question?.CorrectAnswer?'success':'default'):'default'} className="fs-5" key={`qo-${item.ID}`}>
                            <span className="text-primary fw-bold">{item.Letter}.</span> <span dangerouslySetInnerHTML={{__html:item.Content}}></span>
                            <QuestionOptionDescriptionDisplay showDescription={state.showQuestionAbout} optionDescription={optionDesc} />
                           </ListGroup.Item>
                })}
                </ListGroup>
        </CardBody>
        <QuestionPreviewNav isMore={question.IsMore!} 
                            setShowComments={setShowComments}
                            showDescription={state.showQuestionAbout} 
                            isLoadingDescription={isLoadingAbout.current}
                            commentsCount={question.CommentsCount}
                            onClickShowMoreAboutQuestion={(mode)=>setState({...state,showQuestionAbout:mode})}
                            showAnswer={state.showAnswer}
                            onClickShowCorrect={(mode)=>setState({...state,showAnswer:mode})}
        />
    </Card>
    <ExamQuestionComment questionID={question.ID} show={showComments} setShow={setShowComments} />
    </>
}

export default QuestionPreview;