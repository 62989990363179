import { Dispatch, SetStateAction, useContext } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import GlobalContext, { UserAccessListItem } from "../Componets/GlobalContext";
import { FaCircleXmark, FaDatabase, FaExclamation, FaGears, FaHeart, FaPencil } from "react-icons/fa6";
import { MdFileOpen, MdHistory } from "react-icons/md";


 function ExamNav({activeTab,show,setShow}:{activeTab:string,show:boolean,setShow:Dispatch<SetStateAction<boolean>>}){
    const {globalState} = useContext(GlobalContext);

    let userAccess = globalState.user&&!globalState.user.HasAccess;
    // console.log(globalState);

    function hideNav(){
        setShow(false);
    }

    return <>
        <Offcanvas responsive="lg" show={show} onHide={hideNav}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Egzaminy</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav className="flex-column app-sidenav">
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy" active={activeTab==='otwarte'} onClick={hideNav}>
                            <MdFileOpen /> Otwarte
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy/cem" active={activeTab==='cem'} onClick={hideNav}>
                            <FaDatabase /> Baza pytań CEM
                        </Nav.Link>
                    </Nav.Item>
                    {/* {globalState.access && (globalState.access as UserAccessListItem[]).find(x=>x.SpecID===44)? */}
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy/generator" disabled={userAccess} active={activeTab==='generator'} onClick={hideNav}>
                            <FaGears /> Generator
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy/generator-v2" disabled={userAccess} active={activeTab==='generatorv2'} onClick={hideNav}>
                            <FaGears /> Generator V2
                        </Nav.Link>
                    </Nav.Item>
                    {/* :null} */}
                    {globalState.access && (globalState.access as UserAccessListItem[]).find(x=>x.SpecID===44)?
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy/probny-egzamin" disabled={userAccess} active={activeTab==='egzamin-probny'} onClick={hideNav}>
                            <FaCircleXmark /> Egzamin próbny
                        </Nav.Link>
                    </Nav.Item>:null}
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy/pytania-autorskie" disabled={userAccess} active={activeTab==='autorskie'} onClick={hideNav}>
                            <FaPencil /> Pytania autorskie
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy/ulubione-pytania" disabled={userAccess} active={activeTab==='ulubione'} onClick={hideNav}>
                            <FaHeart /> Ulubione pytania
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy/pytania-ktorych-nie-widziales" disabled={userAccess} active={activeTab==='not-seen'} onClick={hideNav}>
                            <FaExclamation /> Pytania których nie widziałeś
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy/historia" disabled={userAccess} active={activeTab==='historia'} onClick={hideNav}>
                            <MdHistory /> Historia
                        </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link as={Link} to="/app/egzaminy/statystyka" active={activeTab==='statystyka'}>Statystyka</Nav.Link>
                    </Nav.Item> */}
                </Nav>
            </Offcanvas.Body>
        </Offcanvas>
    </>
}

export default ExamNav;