import { useState, useRef, Dispatch, SetStateAction, useContext } from "react";
import { RowExamNew } from "../Type/RowExamNew";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { RowUserExamQuestion } from "../Type/RowUserExamQuestion";
import { Alert, Badge, CardBody, CardTitle, NavDropdown } from "react-bootstrap";
import { Card } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import { MdChecklist, MdQuestionMark, MdHeartBroken, MdFavorite, MdEdit } from "react-icons/md";
import { FaAngleRight, FaAngleLeft, FaExclamation } from "react-icons/fa6";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { MouseEventHandler } from "react";
import QuestionOptionsList from "./QuestionOptionsList";
import QuestionLoadingPlaceholder, { QuestionNoAccessPlaceholder } from "./QuestionLoadingPlaceholder";
import DrawSpinner from "./DrawSpinner";
import { stateQuestion } from "./Question/State";
import ExamQuestionComment from "../Question/Comment";
import GlobalContext from "./GlobalContext";
import { QuestionTitle } from "../Question/Component/Title";
import { QuestionFavoriteButton } from "../Question/Component/Favorite";
import { QuestionCardContent } from "../Question/Component/Content";

interface StateQuestionNav{
    rowQuestion:RowUserExamQuestion;
    rowExam:RowExamNew;
    isQuestionMoreLoading:boolean;
    // onClickShowMoreAboutQuestion:MouseEventHandler<HTMLElement>;
    setShowComments:Dispatch<SetStateAction<boolean>>;
    data:stateQuestion,
    setData:Dispatch<SetStateAction<stateQuestion>>;
}

function QuestionNav({rowQuestion,rowExam,isQuestionMoreLoading,setShowComments,setData,data}:StateQuestionNav)
{
    let prevQuestionLink = null;
    let nextQuestionLink = null;
    let isMore = false;
    let navigate = useNavigate();

    // console.log(`/app/egzamin/${rowExam.ID}/${rowQuestion.NextOrder}`);

    if (rowQuestion.PreviousOrder!==null)
        prevQuestionLink = <Nav.Item><Nav.Link as={Link} to={`/app/egzamin/${rowExam.ID}/${rowQuestion.PreviousOrder}`}><FaAngleLeft /><span className="d-none d-md-inline"> Poprzednie pytanie</span></Nav.Link></Nav.Item>
    else
        prevQuestionLink = <Nav.Item><Nav.Link href='#' disabled><FaAngleLeft /><span className="d-none d-md-inline"> Poprzednie pytanie</span></Nav.Link></Nav.Item>

    if (rowQuestion.NextOrder!==null)
        nextQuestionLink = <Nav.Item><Nav.Link as={Link} to={`/app/egzamin/${rowExam.ID}/${rowQuestion.NextOrder}`} ><span className="d-none d-md-inline">Kolejne pytanie</span> <FaAngleRight className="fw-bold" /></Nav.Link></Nav.Item>
    else
        nextQuestionLink = <Nav.Item><Nav.Link title="Zakończ i wyświetl podsumowanie" as={Link} to={`/app/egzamin-wynik/${rowExam.ID}`}><MdChecklist /> Zakończ {rowExam.Type==='nauka'?'naukę':'egzamin'}</Nav.Link></Nav.Item>

    
    if (rowQuestion.IsMore===true){
        isMore=true;
    }else{
        if (rowQuestion.Options?.some(a => a.IsMore === true))
            isMore=true;
    }

    let questionsLeft = rowExam.QuestionCount-(rowQuestion.AnswerCount??0);
    // console.log(rowQuestion.Answer);
        
    return <>
        <Nav fill variant="underline" className="mt-3">
            {prevQuestionLink}
            {/* <Nav.Item>
                <Nav.Link className="position-relative" disabled={(rowExam.Type==='egzamin' && rowQuestion.Answer===null)} title="Pokaź omówienie do tego pytania" onClick={onClickShowMoreAboutQuestion}><MdComment /> <span className="d-none d-md-inline">Pokaż omówienie</span><span className="d-sm-inline d-md-none">Dyskusja</span></Nav.Link>
            </Nav.Item> */}
            {isMore === true ? 
            <Nav.Item>
                <Nav.Link className="position-relative" disabled={(rowExam.Type==='egzamin' && rowQuestion.Answer===null)} 
                    active={data.showQuestionAbout}
                    title="Pokaź omówienie do tego pytania" onClick={() => setData({...data,showQuestionAbout:!data.showQuestionAbout})}><MdQuestionMark /> <span className="d-none d-md-inline">Pokaż omówienie</span><span className="d-sm-inline d-md-none">Omówienie</span></Nav.Link>
            </Nav.Item> : null }

            <Nav.Item>
                <Nav.Link onClick={()=>setShowComments(true)} title="Pokaż komentarze do tego pytania">
                    <span className="position-relative">Dyskusja&nbsp;&nbsp;
                        {rowQuestion.CommentsCount! > 0 ? <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{rowQuestion.CommentsCount}</span> : null}
                    </span>
                </Nav.Link>
            </Nav.Item>

            {questionsLeft===0 && rowQuestion.NextOrder!==null ? 
                <Nav.Item><Nav.Link title="Zakończ i wyświetl podsumowanie" as={Link} to={`/app/egzamin-wynik/${rowExam.ID}`}><MdChecklist /> Zakończ</Nav.Link></Nav.Item>
                :
                null}
            {nextQuestionLink}
        </Nav>
    </>
}

export function Question({questionID,exam,onOptionClick,lockQuestion}:{questionID:string,exam:RowExamNew,lockQuestion:boolean,onOptionClick:(option: string) => void|null; }) {
    const [data,setData] = useState<stateQuestion>({rowQuestion:null,rowQuestionAbout:null,showQuestionAbout:false,lockQuestion:false,accessMessage:null});

    let rowQuestion                     = data.rowQuestion as RowUserExamQuestion;
    let dataQuestionAbout               = data.rowQuestionAbout;
    let showQuestionAbout               = data.showQuestionAbout;
    let [showComments,setShowComments]  = useState<boolean>(false);
    let loadingQuestionAbout            = useRef<boolean>(false);
    let loadingQuestion                 = useRef<boolean>(false);

    if (rowQuestion){
        if (parseInt(questionID) !== rowQuestion.Order ){
            setData({rowQuestion:null,rowQuestionAbout:null,showQuestionAbout:false,lockQuestion:false,accessMessage:null});
            return <></>
        }
    }

    if (dataQuestionAbout===null && showQuestionAbout===true && loadingQuestionAbout.current===false){
        loadingQuestionAbout.current=true;
        axios.get(`/pytanie/get-description/${rowQuestion?.QuestionID}/${rowQuestion?.ID}`).then((response) => {
            loadingQuestionAbout.current=false;
            setData({...data,rowQuestionAbout:response.data.data});
          }, (error) => {
            loadingQuestionAbout.current=false;
        });
    }

    if (loadingQuestion.current===true) return <QuestionLoadingPlaceholder />

    if (rowQuestion===null&&loadingQuestion.current===false&&data.accessMessage===null){
        loadingQuestion.current=true;
        axios.get(`/egzamin/get-user-exam-question/${exam.ID}/question/${questionID}`).then((response) => {
            loadingQuestion.current=false;
            if (response.data.access){
                setData({...data,accessMessage:response.data.access,rowQuestion:response.data.data});
            }else{
                setData({...data,rowQuestion:response.data.data,accessMessage:null});
            }
          }, (error) => {
            loadingQuestion.current=false;
        });

        return <QuestionLoadingPlaceholder />
    }else{
        // console.log(rowQuestion);
    }

    if (data.accessMessage!==null){
        return <QuestionNoAccessPlaceholder message={data.accessMessage} rowQuestion={rowQuestion} />
    }

    if (rowQuestion===null)return <QuestionLoadingPlaceholder />

    if (lockQuestion) return <QuestionLoadingPlaceholder />

    let rowQuestionPreview = {ID:rowQuestion.QuestionID, Type:null, Content:rowQuestion.Content, CorrectAnswer:rowQuestion.CorrectAnswer,
                              IsMore:rowQuestion.IsMore, ExamYear:rowQuestion.ExamYear, ExamSeason:rowQuestion.ExamSeason, 
                              ExamSeasonName:rowQuestion.ExamSeasonName, ExamOrder:rowQuestion.ExamOrder, LibraryName:rowQuestion.LibraryName,
                              LibraryID:rowQuestion.LibraryID, FavoriteID:rowQuestion.FavoriteID,
                              SpecID:rowQuestion.SpecID, SpecName:rowQuestion.SpecName, Tags:data.rowQuestionAbout?.Tags!, 
                              Source:data.rowQuestionAbout?.Source!, Options:rowQuestion.Options, CommentsCount:rowQuestion.CommentsCount
    };

    return (
        <>
        <Helmet>
            <title>{exam.Name} - {questionID}</title>
        </Helmet>
        <Card key={`question-card-${questionID}`} className="mb-4">
            <CardBody className="lh-base">
                    <QuestionCardContent rowAbout={data.rowQuestionAbout} 
                                         showAbout={showQuestionAbout} 
                                         rowQuestion={rowQuestionPreview} />
                    <QuestionOptionsList 
                        rowExam={exam} 
                        questionState={data} 
                        rowOptionsAbout={dataQuestionAbout?.Options} 
                        setRowQuestionData={setData}
                        onOptionClick={onOptionClick}
                        showDescription={showQuestionAbout} />
                    {/* <QuestionAnswerList rowQuestion={rowQuestion} rowExam={exam} rowQuestionAbout={dataQuestionAbout} showDescription={showQuestionAbout} /> */}
                {/* </CardText> */}
            </CardBody>
                <QuestionNav 
                    rowQuestion={rowQuestion as RowUserExamQuestion} 
                    rowExam={exam} 
                    isQuestionMoreLoading={loadingQuestionAbout?true:false}
                    setData={setData}
                    data={data}
                    // onClickShowMoreAboutQuestion={() => setData({...data,showQuestionAbout:true})}
                    setShowComments={setShowComments}
                />
        </Card>
        <ExamQuestionComment questionID={rowQuestion.QuestionID} show={showComments} setShow={setShowComments} />
        </>
    );
}

export default Question;

function QuestionReportButton({data}:{data:stateQuestion}){
    return <Button variant="danger" size="sm" className=""><FaExclamation /> Zgłoś</Button>
}
