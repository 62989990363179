import { useContext, useState } from "react";
import { RowUserExamQuestion } from "../../Type/RowUserExamQuestion";
import { RecordQuestionPreview } from "../Interface/QuestionPreview";
import GlobalContext from "../../Componets/GlobalContext";
import { MdCheck, MdCheckBoxOutlineBlank, MdEdit, MdLink, MdPreview, MdRequestQuote } from "react-icons/md";
import { Button, Form, Modal } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';
import { useLocation, useNavigate } from "react-router-dom";

export function QuestionTitle({question}:{question:RowUserExamQuestion|RecordQuestionPreview}){
    let qSubTitle = null;
    if (question.LibraryName!==null)
        qSubTitle = <>
            <small>Autorskie <i>{question.SpecName}</i> : {question.LibraryName}</small>
        </>
    if (question.ExamYear!==null)
        qSubTitle = <>
            <small>PES <i>{question.SpecName}</i> : {question.ExamYear}/{question.ExamSeasonName}/pytanie {question.ExamOrder}</small>
        </>
    return <>
        <div className="position-relative mb-2 mb-lg-0">
            Pytanie {"Order" in question ? question.Order : question.ExamOrder}             
            <small className="d-block">
                {qSubTitle}
            </small>
        </div>
    </>
}

export function QuestionEdit({question}:{question:RowUserExamQuestion|RecordQuestionPreview}){
    const {globalState,globalDispatch}              = useContext(GlobalContext);
    let questionID                                  = "QuestionID" in question ? question.QuestionID : question.ID;

    let buttonAdmin = null;
    if (globalState){
        if (globalState.user){
            if ("Type" in globalState.user){
                if (globalState.user.Type==='admin'){
                    let editHref = `https://zdajlekko.pl/admin/library/edit-question/${questionID}`;
                    if (question.ExamYear!==null)
                        editHref = `https://zdajlekko.pl/admin/question/edit/${questionID}`;
                    buttonAdmin = <Button target="_blank" variant="primary" href={editHref} size="sm"><MdEdit /> Edytuj<span className="d-none d-md-inline">  pytanie</span></Button>
                }
            }
        }
    }
    return <>{buttonAdmin}</>
}

export function QuestionProposal({question}:{question:RowUserExamQuestion|RecordQuestionPreview}){
    let [show,setShow]    = useState<boolean>(false);
    const location        = useLocation();
    const navigation      = useNavigate();
    // console.log(location.pathname)

    const handleClose = () => setShow(false);

    return <>
        <Button size="sm" variant="outline-primary" onClick={()=>setShow(true)} ><MdRequestQuote /> Zaproponuj!!</Button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Zaproponuj
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Jeśli uważasz, że warto coś dodać do omówienia pytania, dodać kolejny tag lub zmienić w ogóle kategorię pytania. <strong>ZAPROPONUJ!! :-)) </strong></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Zamknij</Button>
                <Button variant="primary" onClick={() => navigation(`/app/pytanie/${question.ID}/propozycja`)}>Mam propozycję!!</Button>
            </Modal.Footer>
        </Modal>
    </>
}