import { Alert, Col, Row } from "react-bootstrap";
import { FaQuestion } from "react-icons/fa6";
import { ErrorResponse, isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function MyAppError(){
    const error = useRouteError();
    let errorMessage: string;

    if (isRouteErrorResponse(error)) {
        // error is type `ErrorResponse`
        errorMessage = (error as ErrorResponse).data || error.statusText;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === 'string') {
        errorMessage = error;
    } else {
        console.error(error);
        errorMessage = 'Unknown error';
    }
    console.log(error);
    return <>
    <div className="container">
        <Row>
            <Col>
                <Alert variant="danger" className="my-3">
                    <h1>Wystąpił błąd</h1>
                    <p>{errorMessage}</p>
                    <p><FaQuestion />Spróbuj się ponownie <a href="/start/logout">zalogować</a> lub <a href="/app/">odśwież stronę</a>.</p>
                </Alert>
            </Col>
        </Row>        
    </div>
    </>;
}