//import Navbar from 'react-bootstrap/Navbar';
//import { Outlet } from "react-router-dom";
//import Nav from 'react-bootstrap/Nav';
import { useEffect, useContext } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from "react-router-dom";
import DropdownDivider from 'react-bootstrap/DropdownDivider'
import GlobalContext from './GlobalContext';
import axios from 'axios';

export function IdentityNavBar() {
  const {globalState,globalDispatch} = useContext(GlobalContext);
  function naviClick(e) {
    e.preventDefault();
    navigate(e.target.getAttribute('href')); 
  }

  async function loadUser(){
    await axios({url:'/uzytkownik',maxRedirects:0}).then((r)=>{
      let data=r.data;
      if (data.identity&&data.identity.ID===null&&data.identity.Created!==null){
        globalDispatch({type:'SET_USER',user:data.identity,access:data.access});
      }else{
        window.location='https://zdajlekko.pl/';
      }
    });
  }

  const navigate = useNavigate();
  useEffect(()=>{
    loadUser();
  },[]);
    // useEffect(() => {
    //     fetch('/uzytkownik')
    //       .then((res) => {
    //         return res.json();
    //       })
    //       .then((data) => {
    //         if (data.identity&&data.identity.ID===null&&data.identity.Created!==null){
    //           globalDispatch({type:'SET_USER',user:data.identity,access:data.access});
    //         }else{
    //           window.location='https://zdajlekko.pl/';
    //         }
    //       });
    //   }, []);
  return (
    <>
      <NavDropdown title={globalState.user && globalState.user.Name} id="basic-nav-dropdown" align="end" className=''>
        <NavDropdown.Item href="/app/moje-konto" onClick={naviClick}>Moje konto</NavDropdown.Item>
        <DropdownDivider />
        <NavDropdown.Item href="/start/logout">Wyloguj się</NavDropdown.Item>
      </NavDropdown>
    </>
  );
}

export default IdentityNavBar;